export default class PricingService {
  constructor(interval, locations, registers) {
    this.interval = interval
    this.selected_locations_number = locations
    this.selected_registers_number = registers
    this.plansCostDetails = this.setPlan()
  }

  prices = () => {
    return {
      monthly: {
        basic: 149,
        advanced: 229,
        pro: 343,
        extraRegister: 79
      },
      annually: {
        basic: 1428,
        advanced: 2148,
        pro: 3228,
        extraRegister: 708
      }
    }
  }

  pricingObject = plan => {
    const prices = this.prices()
    let planCost = {}
    const hasMoreRegisterThanLocations =
      this.selected_registers_number > this.selected_locations_number
    const hasMoreLocations = this.selected_locations_number > 1
    const extraRegisters =
      this.selected_registers_number - this.selected_locations_number

    planCost['basePlanCost'] = prices[this.interval][plan]
    planCost['extraLocationsCost'] = hasMoreLocations
      ? prices[this.interval][plan] * (this.selected_locations_number - 1)
      : 0
    planCost['extraRegistersCost'] = hasMoreRegisterThanLocations
      ? prices[this.interval]['extraRegister'] * extraRegisters
      : 0
    planCost['totalCost'] =
      planCost['basePlanCost'] +
      planCost['extraLocationsCost'] +
      planCost['extraRegistersCost']

    return planCost
  }

  basicPricing = () => {
    const plan = 'basic'
    const planCost = this.pricingObject(plan)
    return planCost
  }

  advancedPricing = () => {
    const plan = 'advanced'
    const planCost = this.pricingObject(plan)
    return planCost
  }

  proPricing = () => {
    const plan = 'pro'
    const planCost = this.pricingObject(plan)
    return planCost
  }

  setPlan = () => {
    let plansCost = {}

    plansCost['basic'] = this.basicPricing()
    plansCost['advanced'] = this.advancedPricing()
    plansCost['pro'] = this.proPricing()
    return plansCost
  }
}
