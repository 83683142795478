import { put } from 'redux-saga/effects'
import StartupActions from '../redux/StartupRedux'

// process STARTUP actions
export function* startup(api) {
  yield put(StartupActions.startupSuccess())
}

export function* startupSuccess(api) {
}
