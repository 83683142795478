import React, { Component } from 'react'
import { Images } from '../Themes'
import { withTranslation } from 'react-i18next'
import SectionStrip from '../components/SectionStrip'
import { Header } from '../components/navbar'
import TryButton from '../components/TryButton'
import InventoryAppSection from '../components/InventoryAppSection'

class RetailLandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'Get Free Demo'
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { t, i18n } = this.props
    document.title = t('title')

    return (
      <React.Fragment>
        <Header ShowToggle={true} demoButtonText={this.state.buttonText} />

        <section className="pt-5 mt-5 mb-0 pb-0">
          <div className="col-lg-9 mb-5 mx-auto text-center">
            <h1 className="h1 u-fw-700 u-fs-45 text-black text-center">
              {t('Discover how to improve your business with us')}
            </h1>
            <p className="h4 u-fs-25 u-fw-500 mt-5 text-black text-center">
              {t(
                'Spend less time on repetitive tasks so you can focus on doing what you love'
              )}
            </p>
          </div>
        </section>

        <section className="pt-1 mx-auto text-center">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12 text-left mx-auto u-pt-20">
                <img
                  className="u-rounded-15 align-self-end  mt-5 mb-0"
                  src={Images.shoppingBasket}
                  alt="FreshSUQ POS"
                />
              </div>
            </div>
          </div>
        </section>

        <SectionStrip t={t} />

        <InventoryAppSection
          modelLeft={true}
          i18n={i18n}
          t={t}
          image={Images.inventoryApp}
          title={t('InventorySectionTitle')}
          subtitle={t('InventorySectionSubTitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <section className="u-pt-50 pb-0 u-h-80vh">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-10 mx-auto u-pt-80">
                <h1 className={`display-4 u-fw-600 text-black text-center`}>
                  {t(
                    'Reports to help you take better control of your business'
                  )}
                </h1>
                <p className="h4 u-fs-25 u-fw-500 mt-5 text-black text-center">
                  {t('ReportsSubHeader')}
                </p>
              </div>
            </div>

            <div className="row u-mt-80 u-mb-80">
              <div className={`col-6 text-center order-lg-1}`}>
                <img
                  className="modal-image u-pt-50"
                  src={Images.model_sales_report}
                  alt="FreshSUQ Store"
                />
              </div>
              <div className={`col-6 text-center order-lg-1}`}>
                <img
                  className="modal-image u-pt-50"
                  src={Images.model_business_expense_report}
                  alt="FreshSUQ Store"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="u-pt-50 pb-0 u-h-80vh">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-10 mx-auto u-pt-80">
                <h1 className={`display-4 u-fw-600 text-black text-center`}>
                  {t('Build a better relationship with your customers')}
                </h1>
                <p className="h4 u-fs-25 u-fw-500 mt-5 text-black text-center">
                  {t('CustomerManagementSubtitle')}
                </p>
              </div>
            </div>

            <div className="row u-mt-80 u-mb-80">
              <div className={`col-6 text-center order-lg-1}`}>
                <img
                  className="modal-image u-pt-50"
                  src={Images.model_easily_manage_your_customers}
                  alt="FreshSUQ Store"
                />
              </div>
              <div className={`col-6 text-center order-lg-1}`}>
                <img
                  className="modal-image u-pt-50"
                  src={Images.model_house_account}
                  alt="FreshSUQ Store"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default withTranslation()(RetailLandingPage)
