import React from 'react'
import ReactDOM from 'react-dom'
// import $ from 'jquery';
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './index.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import App from './containers/App'
import registerServiceWorker from './registerServiceWorker'
import { I18nextProvider } from 'react-i18next'
import translation from './translations/translation'
import './fonts/Almarai/Almarai-Regular.ttf'
import './fonts/DM_Sans/DMSans-Regular.ttf'

ReactDOM.render(
  <I18nextProvider i18n={translation}>
    <App i18n={translation} />
  </I18nextProvider>,
  document.getElementById('root')
)
registerServiceWorker()
