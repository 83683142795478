import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Header } from '../components/navbar'

class TermsPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      pkg: 'demo'
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  onHomeClick() {
    this.props.history.push('/')
  }

  renderContactForm() {
    const { t, i18n } = this.props
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const zf_lang = i18n.language === 'ar' ? 'ar' : 'en'
    const formLink = `https://forms.zohopublic.com/alanhaikal/form/LandingDemo/formperma/CmPhTgc3uyNVDx-QTGuuv3ZJ6_G4rOM7w8DyVLJRDR4?zf_lang=${zf_lang}`

    return (
      <div dir={textDirection} className="p-2 u-rounded-10 py-5 u-h-80vh">
        <div className="row bg-indigo-opacity u-rounded-10 py-5">
          <div className="col-lg-12 mx-auto text-center">
            <h1 className="h1 text-white text-center">
              {t('Get Your Free Demo')}
            </h1>
            <p className="text-white text-center">{t('CompleteThisForm')}</p>
            <p className="text-white text-center">{t('YourDemoContains')}</p>
          </div>
        </div>

        <div className="row justify-content-center" dir={textDirection}>
          <div className="col-md-12 col-lg-12 mx-auto text-center px-0">
            <iframe
              frameBorder="0"
              title="demo request"
              className="zoho-demo-form"
              src={formLink}
            ></iframe>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    document.title = t('title')

    return (
      <div>
        <Header ShowToggle />
        <div className="m-3 u-rounded-10">{this.renderContactForm()}</div>
      </div>
    )
  }
}

export default withTranslation()(TermsPage)
