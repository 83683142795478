import React, { Component } from 'react'
import { Provider } from 'react-redux'
import RootContainer from './RootContainer'
import createStore from '../redux'

// create our store
const store = createStore()

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer I18n={this.props.I18n} />
        <a
          href="https://wa.me/+966557585480"
          id="whatsapp_button"
          className="whatsapp_float whatsapp_badge d-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp fa-lg whatsapp-icon"></i>
          <span className="px-2">WhatsApp</span>
        </a>
      </Provider>
    )
  }
}

export default App
