import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  startupSuccess: null,
  startupFailure: null,
  validateTokenFailure: null,
  validateToken: ['token']
})

export const StartupTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  success: false
})

/* ------------- Reducers ------------- */

export const startupSuccess = state => state.merge({ success: true })

export const startupFailure = state => state.merge({ success: false })

export const validateToken = state => state.merge({ success: false })
export const validateTokenFailure = state =>
  state.merge({ success: true, token: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STARTUP]: startupFailure,
  [Types.STARTUP_SUCCESS]: startupSuccess,
  [Types.VALIDATE_TOKEN]: validateToken,
  [Types.VALIDATE_TOKEN_FAILURE]: validateTokenFailure,
  [Types.STARTUP_FAILURE]: startupFailure
})
