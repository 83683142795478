import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Images } from '../../Themes'
import NavbarToggle from './NavbarToggle'
import { withTranslation } from 'react-i18next'
import { MobileView } from 'react-device-detect'
import MobileTryButton from '../MobileTryButton'

class Header extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collapsed: true,
      showMobileFooter: false
    }

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { i18n } = this.props
    if (i18n.language === 'ar') {
      document.body.classList.toggle('font-face-ar', true)
      document.body.classList.toggle('font-face-en', false)
      document.documentElement.setAttribute('lang', 'ar')
    } else {
      document.body.classList.toggle('font-face-ar', false)
      document.body.classList.toggle('font-face-en', true)
      document.documentElement.setAttribute('lang', 'en')
    }
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  elementInViewport = () => {
    var myElement = document.getElementById('footer')
    var bounding = myElement.getBoundingClientRect()
    var myElementHeight = myElement.offsetHeight
    var myElementWidth = myElement.offsetWidth
    let showMobileFooter
    if (
      bounding.top >= -myElementHeight &&
      bounding.left >= -myElementWidth &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) +
          myElementWidth &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) +
          myElementHeight
    ) {
      showMobileFooter = false
    } else if (window.scrollY > 400) {
      showMobileFooter = true
    }

    this.setState({ showMobileFooter: showMobileFooter })
  }

  handleScroll(event) {
    event.preventDefault()

    if (window.scrollY === 0) {
      document.body.classList.toggle('is-scrolling', false)
    } else {
      document.body.classList.toggle('is-scrolling', true)
    }
    this.elementInViewport()
  }

  onLangChange = lang => {
    const otherLang = lang === 'en' ? 'ar' : 'en'
    let currentPathname = window.location.pathname
      .replace(/\/+$/, '')
      .split('/')
      .filter(x => {
        return x !== ''
      })
    const hasLocale = currentPathname.includes(otherLang)

    if (hasLocale) {
      const index = currentPathname.indexOf(otherLang)
      currentPathname[index] = lang
      const newPath = currentPathname.join('/')
      window.location.replace(`/${newPath}`)
    } else {
      const newPath = [lang, ...currentPathname].join('/')
      window.location.replace(`/${newPath}`)
    }
  }

  renderLangButton() {
    const { i18n } = this.props
    let lang = ''

    if (i18n.languages.includes(i18n.language)) {
      lang = `${i18n.language.toString()}`
    }

    if (lang === 'ar') {
      return (
        <button
          className="nav-link u-bg-transparent border-0 mt-lg-1 mt-md-1 text-left"
          onClick={() => this.onLangChange('en')}
        >
          <p className="h4">English</p>
        </button>
      )
    }

    return (
      <button
        className="nav-link u-bg-transparent border-0  mt-lg-1 mt-md-1 text-left"
        onClick={() => this.onLangChange('ar')}
      >
        <p className="h4">العربية</p>
      </button>
    )
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  onFeaturesClick() {
    this.props.onFeaturesClick()
    this.toggleNavbar()
  }

  onHardwareClick() {
    this.props.onHardwareClick()
    this.toggleNavbar()
  }

  onHomeClick() {
    if (this.props.onHomeClick) {
      this.toggleNavbar()
      return this.props.onHomeClick()
    }
    this.props.history.push('/')
    return this.toggleNavbar()
  }

  onRequestDemoClick() {
    const { i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }
    window.open(`https://home.freshsuq.com/${locale}/setup`)
    if (window.analytics) {
      window.analytics.track(`Navbar Clicked on Try Demo`)
    }

    this.toggleNavbar()
  }

  onDemoClick() {
    const { i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }
    window.open(`https://home.freshsuq.com/${locale}/setup`)
    if (window.analytics) {
      window.analytics.track(`Navbar Clicked on Try Demo`)
    }

    this.toggleNavbar()
  }

  showLinks() {
    const { homeOnly, t, i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }

    if (homeOnly) {
      return (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              href={`${locale}`}
              className="nav-link btn mt-lg-1 mt-md-1 text-left"
              onClick={() => this.onHomeClick()}
            >
              <p className="h5">{t('Home')}</p>
            </a>
          </li>
        </ul>
      )
    }

    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item px-1">
          <a
            href={`${locale}`}
            className="nav-link btn mt-lg-1 mt-md-1 text-left"
          >
            <p className="h5">{t('Home')}</p>
          </a>
        </li>

        <li className="nav-item dropdown px-1">
          <a
            href="/#"
            className="dropdown-toggle nav-link btn mt-lg-1 mt-md-1 text-left"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p className="h5">
              {t('BuseinssTypes')}{' '}
              <i className="fa fa-angle-down text-left"></i>
            </p>
          </a>
          <div
            className="dropdown-menu box-shadow-v2 text-center"
            aria-labelledby="navbarDropdown"
          >
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/retail/grocery-store`}
            >
              {t('groceryStores')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/retail/retail`}
            >
              {t('Retail')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/restaurant`}
            >
              {t('restaurants')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/coffee-shop/`}
            >
              {t('coffeeShop')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/quick-service/`}
            >
              {t('quickService')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/food-truck/`}
            >
              {t('foodTrucks')}
            </a>
          </div>
        </li>

        {/*        <li className="nav-item dropdown px-1">
          <a
            href="/#"
            className="dropdown-toggle nav-link btn mt-lg-1 mt-md-1 text-left"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p className="h5">
              {t('Features')} <i className="fa fa-angle-down text-left"></i>
            </p>
          </a>
          <div
            className="dropdown-menu box-shadow-v2"
            aria-labelledby="navbarDropdown"
          >
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/features/inventory_management`}
            >
              {t('Inventory Management')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/features/business_reports`}
            >
              {t('Reports & analytics')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/features/customer_management`}
            >
              {t('Customer management')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/features/employee_management`}
            >
              {t('Employee Management')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/features/customer_display/`}
            >
              {t('Customer display')}
            </a>
          </div>
        </li>*/}

        {/*        <li className="nav-item px-1">
          <a
            href={`${locale}/hardware`}
            className="nav-link btn mt-lg-1 mt-md-1 text-left"
          >
            <p className="h5">{t('hardware')}</p>
          </a>
        </li>*/}

        <li className="nav-item px-1">
          <a
            href={`${locale}/pricing`}
            className="nav-link btn mt-lg-1 mt-md-1 text-left"
          >
            <p className="h5">{t('Pricing')}</p>
          </a>
        </li>

        <li className="nav-item px-1 d-lg-none">{this.renderLangButton()}</li>
        {/*        <li className="nav-item px-1 mt-5 mt-lg-0">
          <button className="btn btn-primary btn-block text-center p-2 pl-4 pr-4 u-fs-22 mt-1 text-white">
            <a
              className="mb-0 h5 pt-1 text-center text-white"
              href={`${locale}/get-free-demo`}
            >
              {t('requestDemo')}
            </a>
          </button>
        </li>*/}
        <li className="nav-item px-1 mt-5 mt-lg-0">
          <button
            onClick={() => this.onDemoClick()}
            className="btn btn-outline-success btn-block text-center p-2 pl-4 pr-4 u-fs-22 mt-1 text-white"
          >
            <p className="mb-0 h5 pt-1 text-center text-dark">{t('tryNow')}</p>
          </button>
        </li>
      </ul>
    )
  }

  render() {
    const { ShowToggle, showWhiteLogo, i18n, t } = this.props
    const { collapsed, showMobileFooter } = this.state

    let locale = ''
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }

    let mobileFooter = showMobileFooter ? '' : 'd-none'

    return (
      <header className="sticky-top header-inverse u-px-md-60">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light px-md-0 pb-0 u-mt-5">
            <Link className="navbar-brand" to={`${locale}`}>
              <span className="logo-default">
                <img
                  width={125}
                  height={40}
                  src={showWhiteLogo ? Images.logoWhite : Images.logo}
                  alt="FreshSUQ"
                />
              </span>
              <span className="logo-inverse">
                <img width={125} height={40} src={Images.logo} alt="FreshSUQ" />
              </span>
            </Link>

            {ShowToggle && (
              <NavbarToggle
                t={t}
                collapsed={collapsed}
                onClick={this.toggleNavbar}
              />
            )}

            <div
              className={
                'collapse navbar-collapse ' + (collapsed ? '' : 'show')
              }
              id="navbarNav"
            >
              {this.showLinks()}
            </div>
          </nav>
        </div>
        <MobileView>
          <div className={`${mobileFooter} mobileFooter`}>
            <div className="mx-5">
              <MobileTryButton props={this.props} />
            </div>
          </div>
        </MobileView>
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    navOpen: state.nav.open
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header))
)
