import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  openNav: null,
  closeNav: null,
  openNavSuccess: null,
  closeNavSuccess: null
})

export const NavTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  open: false
})

/* ------------- Reducers ------------- */
export const openNav = state => state.merge({ open: true })
export const openNavSuccess = state => state.merge({ open: true })
export const closeNav = state => state.merge({ open: false })
export const closeNavSuccess = state => state.merge({ open: false })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_NAV]: openNav,
  [Types.OPEN_NAV_SUCCESS]: openNavSuccess,
  [Types.CLOSE_NAV]: closeNav,
  [Types.CLOSE_NAV_SUCCESS]: closeNavSuccess
})
