import React from 'react'

const SectionStrip = props => {
  return (
    <section className="py-5 mt-5 bg-light-grey">
      <div className="container">
        <div className="row text-center text-black">
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('Offline Mode Capability')}
            </h5>
          </div>
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('Regular System Updates')}
            </h5>
          </div>
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('home.PrintReceipts')}
            </h5>
          </div>
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('Employee Management')}
            </h5>
          </div>
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('Delivery Management')}
            </h5>
          </div>
          <div className="col-md-2 mt-5 mt-md-0">
            <h5 className="display-md-2 u-p-10">
              {props.t('LowInventoryNotification')}
            </h5>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionStrip
