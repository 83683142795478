import React from 'react'

const GettingStartedLinks = ({ t, videoLink, editLink }) => (
  <React.Fragment>
    <a href={videoLink} target="_blank" rel="noopener noreferrer">
      <p className="u-fs-18 mb-2 mt-2 text-primary">
        {t('howToDoIt')} <span className="fa fa-play-circle"></span>
      </p>
    </a>
    <a href={editLink} target="_blank" rel="noopener noreferrer">
      <p className="u-fs-18 mb-0 text-primary">
        {t('changeHere')} <span className="fa fa-external-link"></span>
      </p>
    </a>
  </React.Fragment>
)

export default GettingStartedLinks
