import React from 'react'
import { Images } from '../Themes'

const textDirection = direction => {
  const textDirection = direction === 'start' ? 'text-lg-left' : 'text-lg-right'
  return textDirection
}

const PersonSection = props => (
  <section className="bg-darkerWhite px-5 pb-0 pt-0 mt-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 pt-5 text-center">
          <img
            className="client-image fadeInLeft"
            src={
              props.female ? Images.client_female_photo : Images.client_photo
            }
            alt="FreshSUQ analytics"
          />
        </div>

        <div className="col-lg-6 pt-5 ml-auto">
          <h2
            className={`h1 text-black pl-2 text-center ${textDirection(
              props.direction
            )}`}
          >
            {props.title}
          </h2>
          <p
            className={`u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4 ${textDirection(
              props.direction
            )} text-center`}
          >
            {props.subtitle}
          </p>
          <div
            className={`d-flex justify-content-lg-${props.direction} justify-content-center`}
          >
            {props.renderButton}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PersonSection
