import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

// components
import { Header } from '../components/navbar'

class SupportPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    const top = document.getElementById('top')
    top.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  routeTo(path) {
    this.props.history.push(path)
  }

  render() {
    const { t } = this.props
    document.title = t('SupportTitle')

    return (
      <div>
        <Header ShowToggle onDemoClick={true} />

        <section className="u-py-100 mt-5 u-pb-lg-150 u-flex-center">
          <div className="container">
            <div className="row box-shadow-v1 u-py-40">
              <div className="col-12 text-center">
                <h1 className="text-center">{t('Support')}</h1>
              </div>
              <div className="col-12 text-center mt-5">
                <p className="u-fs-25 text-center ">
                  {t('Support_msg1')}. {t('Support_msg2')}{' '}
                  <a
                    className="text-primary"
                    href="mailto: support@freshsuq.com"
                  >
                    {t('Support')}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation()(SupportPage)
