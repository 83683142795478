export default {
  translations: {
    approvedBy: 'معتمد من',
    home: {
      scanIt: 'استخدام الباركود',
      scanItSubtitle:
        'نظام نقاط البيع فرش سوق يجعل عملية البيع سهلة . استخدم ماسح الباركود لإضافة المنتجات إلى طلبات العميل',
      PrintReceipts: 'يدعم الفاتورة الإلكترونية',
      PrintReceiptsSubtitle:
        'قم دائمًا باصدار الإيصالات الإلكترونية. يسهل عليك فرش سوق اتباع اللوائح الحكومية لإصدار الإيصالات الإلكترونية. تحتوي على متطلبات الفاتورة الضريبية',
      ManageInventory: 'إدارة المخزون',
      ManageInventorySubtitle:
        'يجعل فرش سوق من السهل إضافة وإدارة منتجاتك إلى مخزونك. استخدم تطبيق المخزون المجاني من فرش سوق لإدارة مخزونك بطريقة اسرع',
      MultipleLocations: 'إدارة الفروع',
      MultipleLocationsSubtitle:
        'يتيح لك فرش سوق إدارة جميع المنتجات والمخزون والعملاء والمبيعات من مكان واحد . إنشاء تقرير المبيعات لفرع واحد أو عدة فروع في نفس الوقت'
    },
    inventory: {
      InventorySetup: 'سهولة إعداد المخزون',
      InventorySetupSubtitle:
        'فرش سوق يجعل إعداد المخزون أمرًا سهلاً. تطبيق المخزون للهاتف المحمول يسمح لك بسهولة اضافة وإدارة منتجاتك من مكان واحد . قم بعرض مستويات المخزون لكل من الفروع الخاصة بك',
      UnlimitedStock: 'أضف منتجات غير محدودة',
      UnlimitedStockSubtitle:
        'قم بتشغيل متجرك بشكل أكثر كفاءة من خلال التحكم في كل منتج في المخزون. فرش سوق يمنحك منتجات غير محدودة ، يمكنك استيراد المنتجات واحدًا تلو الآخر أو بمجموعات كبيرة',
      StockAlert: 'تنبيه انخفاض المخزون',
      StockAlertSubtitle:
        'راقب مستويات مخزون منتجاتك بسهولة مع فرش سوق. تلقي تنبيه عندما يصل أي منتج إلى الحد الأدنى من الكمية. يجب أن يحافظ متجرك دائمًا على مستوى مخزون مثالي',
      PurchaseOrders: 'طلبات الشراء',
      PurchaseOrdersSubtitle:
        'تعرف على أفضل وقت لإعادة تخزين متجرك. يمكنك الآن استخدام نظام أوامر الشراء من فرش سوق لإنشاء أوامر إعادة التخزين من قائمة البائعين',
      InventoryCount: 'جرد المخزون',
      InventoryCountSubtitle:
        'استخدم تطبيق المخزون لحساب كل منتج في المخزون ومستويات مخزونهم. لن تحتاج أبدًا إلى استخدام قلم وورقة لحساب المخزون'
    },
    BusinessReports: {
      SalesReport: 'تقرير المبيعات',
      SalesReportSubtitle:
        'قم بتحليل مبيعاتك من خلال معايير مختلفة ، مثل الوقت والمتاجر والموظفين وأنواع الدفع والمزيد . لدينا جميع التقارير التي تساعدك على تحسين أداء متجرك باستمرار',
      ExpenseReports: 'تقارير النفقات',
      ExpenseReportsSubtitle:
        'احصل على تحاليل دقيقة ستساعدك على فهم مصدر نفقاتك بطريقة سريعة. قم بزيادة أرباحك عن طريق تقليل نفقاتك قدر الإمكان',
      CompleteReports: 'تقارير كاملة',
      CompleteReportsSubtitle:
        'نمي متجرك باستخدام محرك تقارير قوي . لن تضطر أبدًا إلى تخمين أرقام مبيعاتك . احصل على المقاييس الرئيسية التي تهم متجرك من أي مكان'
    },
    CustomerManagement: {
      ManageCustomers: 'إدارة العملاء',
      ManageCustomersSubtitle:
        'وفر الوقت عند إنشاء قاعدة بيانات العملاء الخاصة بك عن طريق إضافة عملائك مباشرة في نقطة البيع. يمكنك إضافة أو إزالة العميل أثناء الطلب',
      HouseAccount: 'الحساب الآجل',
      HouseAccountSubtitle:
        'تتيح لك ميزة الحساب الاجل تتبع حسابات العملاء الخاصة بك. من خلال عملية دفع مبسطة ، يمكنك الآن تحديد نوع الدفع بالحساب الاجل لأي عميل. لن تفوتك تسديد الديون بعد اليوم',
      LoyaltyProgram: 'برنامج الولاء',
      LoyaltyProgramSubtitle:
        'قم بزيادة مبيعاتك والاحتفاظ بالعملاء من خلال مكافأة العملاء على تكرار الزيارة . نظام مرن وسهل الاستخدام ، يمكنك تخصيص قواعد الولاء الخاصة بك للسماح لعملائك بكسب نقاط الولاء لكل زيارة أو حسب منتج معين'
    },
    EmployeeManagement: {
      EmployeeShift: 'حضور وانصراف الموظفين',
      EmployeeShiftSubtitle:
        'قم  بادارة جميع موظفيك مع فرش سوق . يمكن للموظفين تسجيل الدخول باستخدام الرقم السري الخاص بهم في بداية  كل مناوبة وسيتتبع النظام اوقات الحضور و الانصراف ',
      EmployeeRights: 'أذونات الموظفين',
      EmployeeRightsSubtitle:
        'قم بحماية متجرك مع فرش سوق. سيُطلب من جميع موظفيك استخدام كلمة المرور الخاصة بهم للوصول إلى نظام نقاط البيع. يمكنك تعيين أذونات لكل واحد منهم',
      EmployeePerformance: 'أداء الموظفين',
      EmployeePerformanceSubtitle:
        'احصل على تقارير تفصيلية لكل موظف من موظفيك . تعرف على ساعات عملهم أو أداء مبيعاتهم أو قم بتتبع سجل طلباتهم'
    },
    CustomerDisplay: {
      ConvenientForCustomers: 'عمليات بيع اسرع',
      ConvenientForCustomersSubtitle:
        'قم بتطوير تجربة المبيعات لعملائك و موظفيك من خلال نظام عرض طلبات العملاء . امنح عملائك الاطلاع على معلومات طلباتهم قبل إجراء الدفع النهائي',
      WorksOffline: 'يعمل بدون الإنترنت',
      WorksOfflineSubtitle:
        'لا تحتاج إلى توصيل نظام عرض طلبات العملاء بالإنترنت . يعمل على جهاز لوحي منفصل عن نظام نقاط البيع لديك'
    },
    pricing: {
      title:
        'يعمل فرش سوق مع كل المتاجر بجميع أنواعها وأحجامها وندرك أن كل نوع عمل له احتياجات فريدة'
    },
    description:
      'فرش سوق نظام كاشير و نقاط البيع مخصص الادارة المطاعم  و التموينات ، استخدام افضل التقنية  لإدارة المخزون و المبيعات يعمل مع أجهزة الاندرويد و الايباد',
    tryItForFree: 'جرب تطبيق فرش سوق الآن بدون عقد',
    tryItForFreeSubtitle: 'قم بتنزيل التطبيق الآن وابدأ البيع في غضون دقائق',
    ProfessionalServices: 'خدمة الدعم',
    ProfessionalServicesSubtitle:
      'لدينا فريق من المتخصصين في الدعم على استعداد لمساعدتك في إعداد متجرك . نحن على استعداد تام لمساعدتك في أي مرحلة من مراحل تطوير متجرك . متواجدون عن طريق الدردشة الحية أو البريد الإلكتروني أو الهاتف ',
    Pricing: 'الأسعار',
    ReportsSubHeader:
      'بإمكانك متابعة جميع أنواع المبيعات من أي مكان. احصل على تقارير تفصيلية تغنيك عن زيارة المحل اليومية للمتابعة',
    StockSubtitle:
      'فرش سوق يساعدك في تتبع السلع المتاحة في المحل. بالإضافة, سوف تحصل على تنبيه في حال انخفاض السلع',
    CustomerManagementSubtitle:
      'عملائك هم الأساس لنجاح مشروعك , مع فرش سوق  تقدر تحفظ معلوماتهم وتتواصل معهم لزيادة مبيعاتك في أوقات العروض',
    'Spend less time on repetitive tasks so you can focus on doing what you love':
      'اقض وقتا أقل على المهام المتكررة حتى تتمكن من التركيز على فعل ما تحب',
    title: 'فرش سوق نظام كاشير | نقاط البيع | ادارة المطاعم',
    TitlePart: ' | فرش سوق نقاط البيع',
    InventoryTitle: 'ادارة المخزون | فرش سوق نقاط البيع',
    ReportsTitle: 'التقارير | فرش سوق نقاط البيع',
    CustomerManagementTitle: 'إدارة العملاء | فرش سوق نقاط البيع',
    EmployeeManagementTitle: 'إدارة الموظفين | فرش سوق نقاط البيع',
    CustomerDisplayTitle: 'شاشة عرض للعملاء | فرش سوق نقاط البيع',
    HardwareTitle: 'الاجهزه | فرش سوق نقاط البيع',
    PricingTitle: 'الاسعار | فرش سوق نقاط البيع',
    RetailTitle: 'حلول التجزئة | فرش سوق نقاط البيع',
    GroceryStoreTitle: 'التموينات | فرش سوق نقاط البيع',
    RestaurantsTitle: 'المطاعم | فرش سوق نقاط البيع',
    SupportTitle: 'الدعم الفني | فرش سوق نقاط البيع',
    'Learn More': 'تعرف على المزيد',
    'Get Free Demo': 'عرض تـجريبي',
    'Get Your Free Demo': 'طلب ﻋﺮﺽ ﺗﺠﺮﻳﺒﻲ',
    requestDemo: 'عرض تـجريبي',
    YourDemoContains: 'يحتوي هذا العرض على دورة تدريبية لتطبيقات فرش سوق',
    CompleteThisForm: 'تواصل مع فرش سوق لتحصل على عرض تجريبي مجاني',
    'Everything For Your Business': 'نـظـام نقـاط البـيع و الكاشـير',
    'Simplifies running your business, by allowing you to streamline your orders':
      'فـرش سـوق نـظام متكامل لنقـاط البـيع و الـكاشـير سهل الاستخدام الادارة المطاعم  و محلات التجزئة يـعـمل مع جميع أجهزة الانـدرويد و الايبـاد',
    'We will be in contact soon': 'We will be in contact soon',
    'Discover how to improve your business with us':
      'اكتشف الطريقة الافضل للإدارة متجرك مع فرش سوق لنقاط البيع',
    'Your full name': 'اسمك الكامل',
    'Your Phone number': 'رقم هاتفك',
    'Your email': 'بريدك الإلكتروني',
    'You must write a correct e-mail': 'You must write a correct e-mail',
    Submit: 'سجل',
    'Select Country': 'اختار الدولة',
    SelectPhoneCode: 'رمز المنطقة',
    'Grocery Store': 'بقالة',
    Retail: 'محلات التجزئة',
    'Food Truck': 'عربة الطعام',
    'Coffee Shop': 'المقهى',
    Bakary: 'المخبز',
    '+More': 'و أكثر +',
    'Your Store Deserves Better System':
      'متجرك يستحق أفضل نظام نقاط بيع متاحة في السوق',
    'No problem is too small':
      'لا توجد مشكلة صغيرة جدًا لخبراء الدعم لدينا، متاحين طوال العام للرد على مكالماتك وعلى بريدك الإلكتروني',
    'Remote Support 24/7': 'الدعم عن بعد 24/7',
    'Employee Training': 'تدريب الموظفين',
    'Learn the system':
      'تعلّم النظام ودرّب موظفيك بسرعة، سيعمل معك عضو فريق مُخصّص لتخصيص إعدادات النظام لديك',
    'Multi Language Support': 'دعم متعدد اللغات',
    'Your system supports a variety of languages':
      'ندعم مجموعة متنوعة من اللغات، بدءًا من العربية والإنجليزية، يُمكن لكل موظف اختيار لغته الفردية عند استخدام النظام',
    'Powerful Features That Go the Distance':
      'نظام بمميزات فعالة تصلكم أينما كنتم',
    'Delivery Management': 'إدارة توصيل الطلبات',
    'Manage your orders':
      'أدر طلباتك ومواعيد تسليمها مع أفضل وأسرع نظام تسليم، قم بتعيين أوامر للسائقين واحصل على تقرير إثبات التوصيل',
    'Offline Mode Capability': 'يعمل من دون إنترنت',
    'Internet connectivity issues, keep using your system and all your data and work is automatically synced to the cloud once you are back online':
      'في حالة وجود أي مشكلة تتعلق بالاتصال بالإنترنت، تستطيع الاستمرار في استخدام نظامك، حيث تتم مزامنة جميع بياناتك وأعمالك تلقائيًا بمجرد اتصالك بالإنترنت',
    'Employee Management': 'إدارة الموظفين',
    'Use our multi-level':
      'استخدم نظام مراقبة الوصول مُتعدّد المستويات الخاص بنا لإدارة وصول موظفيك، وأدِر الجدول الزمني للموظفين ومهامهم وأدائهم',
    'Customer Display Screen': 'شاشة عرض للعملاء',
    'Improve your customer experience':
      'عزز تجربة عملائك من خلال دقة الطلب وسرعة الخدمة',
    LowInventoryNotification: 'منبه انخفاض المنتجات في المخزن',
    LowInventoryMsg:
      'يقوم نظام المخزن بتنبيهك تلقائيًا في حالة انخفاض مستويات المخزن إلى ما دون الكميه التي تحددها',
    'Regular System Updates': 'نـظام سـحابي آمن',
    'Updates allows your store to take advantage of newer features that have been recently introduced to the platform, with no cost to you':
      'تساعد التحديثات متجرك على الاستفادة من أحدث المميزات التي يتم توفيرها مؤخرًا في المنصة دون تكبُّد أي تكلفة إضافية',
    'Automate Your Business': 'تحكّم بمتجرك بشكل أفضل',
    'Control your business':
      'أدر متجرك من خلال منصة واحدة، أصبحت إدارة موظفيك وتتبع المخزن الخاص بك أمرًا بسيطًا مع منصة فرش سوق، يمكنك استخدم لوحة المتابعة أو وحدة التحكم الإدارية لتبسيط جميع عمليات إدارة مشروعك',
    'Transform your tablet into an intelligent point of sale machine':
      'اجعل من جهازك اللوحي أداة بيع ذكية',
    'Inventory App': 'تطبيق جرد المخزن',
    'Manage your inventory':
      'أدر المخزن الخاص بك من خلال تطبيق الجرد لدينا، استخدم التطبيق للحصول على الجرد المالي للمخزون أو إضافة أو إزالة عناصر من مخزونك',
    'Data Security': 'أمن البيانات',
    'We protect your sensitive business data and your customers’ information from potential threats with our reliable and secure platform':
      'نحمي بيانات متجرك الحساسة ومعلومات عملائك من التهديدات المحتملة باستخدام منصتنا الموثوقة والآمنة',
    'Third Party Integration': 'تطبيقات الطرف الثالث',
    'Integrate third party apps directly':
      'استخدم تطبيقات الطرف الثالث مباشرة في نظامك، احصل على المزيد من الإمكانات دون تكبد تكاليف تشغيل إضافية',
    'Real-time Analytics': 'تحليل فوري لبيانات متجرك',
    'Utilize your real-time sales':
      'استخدم بيانات المبيعات أو المخزن أو العملاء لتمكين متجرك من تحقيق المزيد من الأرباح، اجمع أفكار حول المنتجات الأكثر مبيعًا وحول مستويات المخزن، أو استخدم التقارير لمساعدتك في إدارة موظفيك وإنتاجيتهم',
    Home: 'الرئيسية',
    Features: 'المميزات',
    Hardware: 'المعدات',
    Price: 'السعر',
    'Manage Your Employees Easily with FreshSUQ':
      'ادار موظفيك بسهولة مع فرش سوق',
    'Build a better relationship with your customers':
      'ابني علاقة أفضل مع عملائك',
    'Reports to help you take better control of your business':
      'تقارير تساعدك في التحكم بشكل أفضل على متجرك',
    'Best Inventory Management to Increase Your Profit':
      'أفضل نظام لإدارة المخزون لزيادة أرباحك و تقليل تكلفتك',
    'Inventory Management': 'إدارة المخزن',
    'Basic Inventory Management': 'إدارة المخزن "ميزات محدودة"',
    'Basic Features': 'ميزات محدودة',
    Advanced: 'جميع المميزات',
    Annually: 'اشتراك سنويا',
    Annual: 'سنويا',
    Monthly: 'اشتراك شهريا',
    'Annual payment': 'يتم دفع إجمالي المبلغ سنويًا',
    'Monthly payment': 'يتم دفع المبلغ شهريا',
    'Advanced Inventory Management': 'إدارة المخزن "جميع المميزات"',
    'Multiple locations': 'عدد الفروع',
    'one license': 'عدد نقاط البيع',
    'Manage your inventory in real-time':
      'أدِر مخزونك بشكل فوري وتابع كمية المنتجات الموجودة لديك، بالإضافة إلى إرسال تنبيهات عندما تنخفض هذه المنتجات، نظّم المخزن حسب نوع المنتجات',
    'Reporting and Analytics': 'التقارير والتحاليل',
    'Organizes all your data into a clear, friendly, real-time reports, accessible from your desktop or smartphone  Make informed decisions with customized reports':
      'نظّم بياناتك في تقارير واضحة وسلسة وفورية، تكون متاحة على سطح المكتب أو على هاتفك الذكي، بالإضافة إلى وضع قرارات مدروسة مع تقارير مخصصة حسب الطلب',
    'Customer management': 'إدارة الزبائن',
    'Build your customer database, Create customer profiles, and search your customers order history and personal contact information':
      'قم بإنشاء قاعدة بيانات لعملائك وملفات تعريفهم، وتابع سجل طلبات العملاء ومعلومات الاتصال بهم',
    Company: 'الشركة',
    'About Us': 'نبذة عنا',
    Support: 'الدعم الفني',
    Terms: 'شروط الاستخدام',
    Privacy: 'شروط الخصوصية',
    'Contact Info': 'معلومات الاتصال',
    'Copyright 2020 FreshSUQ systems': 'Copyright 2020 FreshSUQ systems',
    'We work hard to help you get the most out of your business':
      ' نعمل على مدار الساعة لمساعدتك في تطوير  متجرك',
    'At FreshSUQ, we’re passionate about providing a hassle-free platform for business owners':
      'في فرش سوق، نحن شغوفون لتوفير منصة خالية من المشاكل لأصحاب المشاريع',
    'We focus on creating solutions for those who need help improving their business workflow and reducing their costs':
      'نركز على تقديم حلول لأولئك الذين يحتاجون إلى المساعدة في تحسين سير عملهم وخفض تكاليفهم',
    'We are truly excited to belong to a community of business owners who are eager to optimize their business':
      'نحن متحمسون جداً لكي نكون جزءا من مجتمع من أصحاب المشاريع الحريصين على تحسين متاجرهم',
    'success is defined by our customers':
      'نجاح شركتنا هو رضا عملائنا، ولهذا السبب نعمل بجد على تحسين مستوى فرش سوق، مهمتنا هي مساعدتك في تبسيط احتياجات متجرك وتوجيهك لتحقيق أرباح أكثر',
    'Fair & Simple Pricing': 'أسعار بسيطة وعادلة',
    '24/7 Support': 'دعم 24/7',
    'Unlimited Products': 'عدد غير محدود من المنتجات',
    'Unlimited Employees': 'عدد غير محدود من الموظفين',
    'Unlimited Customers': 'عدد غير محدود من العملاء',
    'Secure cloud backup': 'نسخ احتياطي آمن يوميًا',
    'Purchase orders': 'أوامر الشراء',
    'Customer display': 'شاشة عرض العميل',
    'Employee timesheet': 'الحضور والانصراف للموظفين',
    'Reports & analytics': 'التقارير والتحليلات',
    'Delivery log & history': 'سجل التوصيل',
    'QuickBooks / xero Integration': 'تكامل QuickBooks / xero',
    'Dedicated account manager': 'مدير حساب خاص',
    'Third party integration': 'تكامل تطبيقات الطرف الثالث',
    'Customized onboarding': 'Customised onboarding',
    month: 'شهريا',
    Free: 'المجانية',
    Basic: 'الأساسية',
    Plus: 'المتقدمة',
    Pro: 'الكاملة',
    Select: 'اختار',
    Recommended: 'المفضلة',
    'We will reach out to you very soon': 'سنتواصل معك قريبًا',
    'Saudi Arabia': 'السعودية',
    'United Arab Emirates': 'الإمارات العربية المتحدة',
    Qatar: 'قطر',
    Bahrain: 'البحرين',
    Egypt: 'مصر',
    Oman: 'عمان',
    Jordan: 'الأردن',
    Lebanon: 'لبنان',
    'United States': 'أمريكا',
    Support_msg1: 'نعمل على بناء بوابة دعم فني شاملة',
    Support_msg2:
      'إذا كان لديك أي أسئلة دعم يرجى مراسلتنا عبر البريد الإلكتروني',
    'Free Trial': 'إشـترك الآن',
    'Try Trial': 'ابـدأ اشـتراكـك الآن',
    pricing_header: 'تجربة مجانية لمدة 45 يومًا مع جميع الباقات',
    pricing_subheader: 'أسعارنا بسيطة ويمكنك إلغاء باقاتك أو تغييرها في أي وقت',
    hardware: 'الأجهزة',
    hardware_list: 'الأجهزة',
    tablet_stand: 'حامل لوحي',
    tablet_stand_msg:
      'حامل الجهاز اللوحي حديد بقفل أمان يناسب جميع الأجهزة اللوحية مقاس 7.9 إلى 12.9 بوصة',
    barcode_scanner: 'ماسح الباركود',
    barcode_scanner_body:
      'ماسح الباركود بلوتوث لاسلكي ، يعمل مع الاندرويد و الايباد',
    SAR: 'ريال سعودي',
    HardwareFor: 'أجهزة فرش سوق  لنظام كاشير',
    ForSupportedList:
      'للحصول على قائمة كاملة بالأجهزة المدعومة من فرش سوق,  يرجى مراسلتنا عبر البريد الإلكتروني على ',
    allRights: 'FreshSUQ 2022 جميع الحقوق محفوظة',
    pricesSubjectToChange: 'تخضع الأسعار للتغيير دون إشعار مسبق',
    LimitedTime: 'عرض لوقت محدود',
    Menu: 'القائمة',
    Faq: 'الأسئلة المتكررة',
    HowToUseFreshSUQ: 'كيف أستخدم تطبيق فرش سوق؟',
    HowToUseFreshSUQMsg:
      'ابدأ بتنزيل التطبيق من متجر آبل أو متجر غوغل. قم بإنشاء حسابك على التطبيق أو على  وحدة التحكم الإدارية . ثم أضف منتجاتك إما باستخدام تطبيق المخزون أو على وحدة التحكم الإدارية . يمكنك تغيير معلومات حسابك وإضافة الضرائب والخصومات على وحدة التحكم الإدارية',
    DoesFreshsuqOffline: 'هل يعمل تطبيق فرش سوق دون اتصال بالإنترنت؟',
    DoesFreshsuqOfflineMsg:
      'فرش سوق يعمل بدون اتصال بالإنترنت. يمكنك إجراء المبيعات وإضافة منتجات جديدة وخصومات بدون اتصال بالإنترنت. ولكن لتحميل مبيعاتك والمنتجات الجديدة أو الخصومات إلى وحدة التحكم الإدارية ، سيتعين عليك الاتصال بالإنترنت لمزامنة بياناتك',
    WhatDevicesWorkWithFreshsuq:
      'ما نوع الأجهزة التي يمكن أن تعمل مع تطبيق فرش سوق؟',
    WhatDevicesWorkWithFreshsuqMsg:
      'يعمل تطبيق فرش سوق على الايباد وأي جهاز لوحي يعمل بنظام الاندرويد. يمكنك توصيل أي ماسح ضوئي للرموز الشريطية بتقنية البلوتوث وطابعة إيصالات نقترح طابعات إيصالات  إبسون وأي درج نقدي. إذا كان جهازك غير مدعوم ، يرجى مراسلتنا عبر البريد الإلكتروني و سنضيفه إلى الأجهزة المدعومة من فرش سوق',
    HowToSignup: 'كيف يمكنني التسجيل في تطبيق فرش سوق؟',
    HowToSignupMsg:
      'يمكنك التسجيل بالضغط على اشتراك الآن  واملأ نموذج التسجيل أو يمكنك تنزيل تطبيق  فرش سوق وإكمال أسئلة التسجيل في التطبيق',
    SignUpForFree: 'إشترك الآن مجانا',
    POSSectionTitle: 'تطبيق واحد لإدارة جميع احتياجاتك',
    POSSectionSubTitle:
      'نعلم أن اللوائح تتغير باستمرار. لكن لدينا كل التقنيات التي تحتاجها',
    HowItWorks: 'كيف يعمل',
    InventorySectionTitle: 'تطبيق المخزن',
    InventorySectionSubTitle:
      'استخدم هاتفك كجهاز مسح ضوئي للرمز الشريطي لإضافة منتجات جديدة أو تحديثها',
    AddNewProducts: 'إضافة المنتجات بسهولة',
    AddNewProductsMsg:
      'يمكنك إضافة المنتجات وإدارتها بسرعة باستخدام فرش سوق. أضف المنتجات مباشرة عبر واجهة فرش سوق أو تطبيق المخزون',
    SalesReports: 'تقارير المبيعات',
    SalesReportsMsg:
      'تخبرك تقارير المبيعات بالمعاملات اليومية لمتجرك. تمنحك نظرة مفصلة على عمليات الشراء والمرتجعات حسب الأيام. تساعدك على تحسين خيارات التوظيف والتسويق',
    TaxReports: 'تقارير الضرائب',
    TaxReportsMsg:
      'مع فرش سوق لا تحتاج إلى برنامج آخر لمعرفة الالتزامات الضريبية. فرش سوق متوافق تمامًا مع اللوائح الضريبية ويقوم بإنشاء تقارير يمكنك استخدامها لتقديم التقارير الضريبية للجهات المعنية',
    PaymentsReports: 'تقارير المدفوعات',
    PaymentsReportsMsg:
      'تمنحك تقارير المدفوعات نظرة عامة على طرق الدفع المفضلة لعملائك. احصل على قائمة مفصلة بإجمالي المبالغ النقدية و مبالغ المدفوعات بالبطاقة والمبالغ المستردة',
    Reports: 'التقارير',
    ReportsSubsection:
      'تقارير توضح لك جميع تفاصيل متجرك - المخزون والمبيعات والعملاء والموظفين',
    BuseinssTypes: 'القطاعات',
    groceryStores: 'التموينات',
    Barbershop: 'صالون الحلاقة',
    restaurants: 'المطاعم',
    coffeeShop: 'المقاهي',
    quickService: 'الخدمات السريعة',
    foodTrucks: 'عربات الطعام',
    forRetails: 'حلول التجزئة',
    stores: 'المحلات',
    forRestaurants: 'حلول المطاعم',
    groceryStoresSubtitle:
      'مصمم بالكامل لإدارة التموينات و السوبر ماركت. يحتوي على أكثر من 100 ألف منتج جاهز للإضافة إلى مخزونك',
    retailStoresSubtitle:
      'نظام مرن مصمم لتجار التجزئة. يحتوي على جميع الأدوات التي تحتاجها لتشغيل وإدارة متجرك ومخزونك من أي مكان',
    restaurantSSubtitle:
      'نظام متكامل مصمم لإدارة المطاعم. استخدم الأدوات المناسبة لتشغيل مطعمك بكفاءة أكبر وخدمة عملائك بشكل أفضل',
    coffeeShopSubtitle:
      'نظام مرن مصمم لإدارة المقاهي. يحتوي على جميع الأدوات لخدمة عملائك بشكل أفضل',
    quickServiceSubtitle:
      'نظام مرن مصمم للخدمات السريعة. يتيح لك بإضافة الطلبات بشكل أسرع والحفاظ على كفاءة موظفيك',
    foodTrucksSubtitle:
      'نظام مرن مصمم لعربات الطعام. يتيح لك بإضافة الطلبات بشكل أسرع والحفاظ على تنظيم موظفيك',
    kds: 'شاشة المطبخ',
    kdsSubtitle:
      'قم بتحسين دقة طلباتك وزيادة الكفاءة ووقت الوجبات بشكل مثالي. يتيح لك نظام شاشة المطبخ تسريع الخدمة من خلال التذاكر التي تظهر على الشاشة لمساعدتك في تقليل الطلبات الفائتة و أوقات الانتظار الطويلة',
    tablesManagement: 'إدارة الطاولات',
    tablesManagementSub:
      'تم تصميم نظام إدارة الطاولات لتسهيل إعداد مخطط مطعمك وإعادة ترتيبه بسهولة. تلقى الطلبات حسب الطاولة ، و ادر سعة مطعمك بطريقة بسيطة',
    inventorySubheader:
      'المخزون هو أهم جانب في متجرك له القدرة على إضافة المزيد من الربحية ، ولهذا السبب قمنا ببناء تطبيق مخصص لإدارة مخزونك بالكامل وجعل عملية إدارة المخزون أسهل',
    groceryStoreText:
      'نعلم أن نظام نقاط البيع هو أهم برنامج في متجرك. لهذا السبب صممنا نظامنا ليعمل حتى لو انقطع الاتصال بالإنترنت',
    restaurantsText:
      'قم بإدارة الطلبات والمبيعات والمدفوعات في مكان واحد باستخدام نظام سحابي آمن. كل التقنيات التي تحتاجها لتشغيل مطعمك في منصة واحدة سهلة الاستخدام.',
    coffeeShopText:
      'قم بإدارة الطلبات والمبيعات والمدفوعات في مكان واحد باستخدام نظام سحابي آمن. كل التقنيات التي تحتاجها لتشغيل المقهى في منصة واحدة سهلة الاستخدام.',
    howToDoIt: 'شاهد كيف تفعل ذلك',
    changeHere: 'تعديل هنا ',
    gettingStartedTitle: 'بدء الإستخدام',
    gettingStartedTitleBody:
      'ستساعدك هذه القائمة على إعداد نـظـام فرش سوق بسرعة. اتبع الخطوات أدناه للبدء',
    generalSettings: 'الإعدادات الرئيسية',
    gettingStartedTitlePage: 'فرش سوق نظام كاشير | بدء الإستخدام',
    generalSettingsSteps: {
      vat_id:
        'قم بإعداد معلومات المتجر العامة بما في ذلك معرف ضريبة القيمة المضافة إذا كان ذلك مطلوبًا',
      store: 'قم بإعداد معلومات الفرع',
      taxes: 'قم بإضافة ضرائب متجرك إذا كان ذلك مطلوبًا',
      employees: 'أضف موظفيك إلى النظام'
    },
    InventorySetup: 'إعداد المخزون',
    InventorySetupSteps: {
      add_categories: 'أضف فئات المنتجات حسب حاجتك',
      add_products: 'أضف المنتجات بشكل فردي أو بالجملة'
    },
    HardwareSetup: 'إعداد الأجهزة',
    tryNow: 'اشتراك الآن',
    numberOfLocations: 'عدد الفروع',
    numberOfRegisters: 'عدد نقاط البيع',
    extraLocations: 'تكلفة الفروع الإضافية',
    extraRegisters: 'تكلفة نقاط البيع الإضافية',
    basePlanCost: 'التكلفة الأساسية للاشتراك',
    discount: 'توفير',
    firstMonthFree: ' أول شهر مجاني',
    creditCardNotReqired: 'بطاقة الائتمان غير مطلوبة للاشتراك',
    seePricing: 'عرض الأسعار',
    subscribeAndUseNow: 'اشترك الآن وابدأ مع فرش سوق في خلال دقائق',
    HardwareSetupSteps: {
      list_of_hardware: 'قائمة الأجهزة التي تحتاجها لتشغيل  نظام فرش سوق',
      tablet: 'جهاز لوحي, ايباد أو اندرويد',
      wifi_router: "راوتر إنترنت واي فاي. 'للاتصال بطابعة إيثرنت'",
      receipt_printer: 'طابعة الإيصالات مع منفذ إيثرنت',
      cash_drawer: "درج نقود 'اختياري'",
      barcode_scanner: "ماسح الباركود بلوتوث 'اختياري'",
      download_app:
        'قم بتنزيل تطبيق فرش سوق من متجر آبل أو متجر جوجل على جهازك اللوحي',
      register:
        'قم بتسجيل  حساب جديد أو تسجيل الدخول على التطبيق باستخدام البريد الإلكتروني وكلمة المرور',
      connect: 'قم بتوصيل الجهاز اللوحي والطابعة بنفس الراوتر ',
      connect_tablet: 'قم بتوصيل الجهاز اللوحي بشبكة الواي فاي',
      connect_printer: 'قم بتوصيل الطابعة بجهاز الراوتر باستخدام كابل إيثرنت',
      set_printer: 'قم بإعداد الطابعة',
      set_barcode: 'قم بإعداد ماسح الباركود'
    }
  }
}
