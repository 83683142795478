import React from 'react'
import { Images } from '../../Themes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = props => {
  const { t, i18n } = useTranslation()
  let locale = ''
  if (i18n.languages.includes(i18n.language)) {
    locale = `/${i18n.language.toString()}`
  }

  const supportLocale = `${locale}`
  const supportWebsite = `https://help.freshsuq.com/portal${supportLocale}/home`

  return (
    <footer id="footer" className="page-footer bg-black text-white">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-5">
              <img
                src={Images.logoWhite}
                alt="FreshSUQ Logo"
                className="footer-logo"
              />
              <div className="pt-4">
                <p className="text-left u-fs-19">{t('allRights')}</p>
              </div>
              <div className="pt-4">
                <img
                  src={Images.cards}
                  width="320px"
                  height={'100%'}
                  alt="FreshSUQ Payments"
                />
              </div>
              <ul className="list-inline social social-rounded social-sm mt-5">
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UC8kMUMDLDQsEUTIrHnS6voA/playlists"
                    target="_blank"
                    aria-label="FreshSUQ Youtube channel"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://twitter.com/freshsuq"
                    target="_blank"
                    aria-label="FreshSUQ twitter"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 mb-5 ml-auto">
              <h5 className="h4 text-white text-left">{t('BuseinssTypes')}</h5>

              <ul className="list-unstyled u-lh-2 pt-3">
                <li>
                  <a href={`${locale}/retail/grocery-store`}>
                    {t('groceryStores')}
                  </a>{' '}
                </li>

                <li>
                  <a href={`${locale}/retail/retail`}>{t('Retail')}</a>{' '}
                </li>

                <li>
                  <a href={`${locale}/restaurants/restaurant`}>
                    {t('restaurants')}
                  </a>{' '}
                </li>

                <li>
                  <a href={`${locale}/restaurants/coffee-shop`}>
                    {t('coffeeShop')}
                  </a>{' '}
                </li>

                <li>
                  <a href={`${locale}/restaurants/quick-service`}>
                    {t('quickService')}
                  </a>{' '}
                </li>

                <li>
                  <a href={`${locale}/restaurants/food-truck`}>
                    {t('foodTrucks')}
                  </a>{' '}
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 mb-5 ml-auto">
              <h5 className="h4 text-white text-left">{t('Company')}</h5>
              <ul className="list-unstyled u-lh-2 pt-3">
                <li>
                  <Link to={`${locale}/about`}>{t('About Us')}</Link>{' '}
                </li>
                <li>
                  <a
                    href={supportWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Support')}
                  </a>
                </li>
                <li>
                  <Link to={`${locale}/terms`}>{t('Terms')}</Link>{' '}
                </li>
                <li>
                  <Link to={`${locale}/privacy`}>{t('Privacy')}</Link>{' '}
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 ml-auto mb-5 mb-lg-0">
              <h5 className="h4 text-white text-left">{t('Contact Info')}</h5>
              <ul className="list-unstyled pt-3">
                <li className="mb-2">
                  <span className="fa fa-phone text-green mr-2" />
                  +966557585480
                </li>
                <li className="mb-2">
                  <span className="fa fa-envelope text-green mr-2" />
                  <a href="mailto:support@freshsuq.com">support@freshsuq.com</a>
                </li>
              </ul>
              <div className="row">
                <div className="col-lg-12">
                  <a
                    href="https://apps.apple.com/sa/app/freshsuq-pos/id1549802659"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Images.app_store}
                      height={'100%'}
                      width={'100%'}
                      className="label-image mx-1 my-2"
                      alt="FreshSUQ pos"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.freshsuq.pos"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Images.play_store}
                      height={'100%'}
                      width={'100%'}
                      className="label-image mx-1 my-2"
                      alt="FreshSUQ pos"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}
export default Footer
