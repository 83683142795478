import React from 'react'

const BusinessTypeSubsection = props => {
  const { image, text, i18n, t, showRetailHowItWorks } = props
  const videoLang =
    i18n.language === 'ar'
      ? 'https://www.youtube.com/embed/--iLynTBV-A'
      : 'https://www.youtube.com/embed/4XFB-nymMvY'

  return (
    <section className="p-4 u-my-80 d-block justify-content-center">
      <div className="row justify-content-center">
        <div className="col-lg-12 m-lg-5 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 u-pt-20 mt-3 row justify-content-center">
              <img className="u-rounded-15" src={image} alt="" />
            </div>
            {showRetailHowItWorks && (
              <a
                href={videoLang}
                data-fancybox="data-fancybox"
                className="u-pos-abs-center text-green btn-play u-mt-80"
              >
                <span className="btn btn-success u-rounded-50 u-fs-28 u-fs-md-28">
                  <span className="fa fa-play-circle u-fs-28 u-fs-md-28 px-2"></span>
                  {t('HowItWorks')}
                </span>
              </a>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <p className="text-center u-fs-30 pt-4">{text}</p>
        </div>
      </div>
    </section>
  )
}

export default BusinessTypeSubsection
