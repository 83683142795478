import immutablePersistenceTransform from '../services/ImmutablePersistenceTransform'
import storage from 'redux-persist/lib/storage'

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.1',
  storeConfig: {
    key: 'primary',
    storage: storage,
    transforms: [immutablePersistenceTransform]
  }
}

export default REDUX_PERSIST
