import React from 'react'
import { withTranslation } from 'react-i18next'

const BusinessTypeStrip = props => {
  const { t, i18n } = props
  let locale = ''
  if (i18n.languages.includes(i18n.language)) {
    locale = `/${i18n.language.toString()}`
  } else {
    locale = `/en`
  }

  return (
    <section className="pt-1 pb-0 u-pull-up u-rounded-20 main-shadow m-5 mt-2 mt-md-5">
      <div className="container py-2 pb-0">
        <div className="row text-center text-black">
          <div className="col-md-4 mt-md-3 mt-1 col-lg mt-4 mt-md-0">
            <h5 className="display-md-2 u-p-20 u-fs-24 text-nowrap">
              <a
                className="text-center"
                href={`${locale}/retail/grocery-store`}
              >
                <span className="fa fa-shopping-basket text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('groceryStores')}</h2>
              </a>
            </h5>
          </div>

          <div className="col-md-4 mt-md-3 mt-1 col-lg mt-4 mt-md-0">
            <h5 className="display-md-2 u-p-20 u-fs-24">
              <a className="text-center" href={`${locale}/retail/retail`}>
                <span className="fa fa-shopping-cart text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('Retail')}</h2>
              </a>
            </h5>
          </div>

          <div className="col-md-4 mt-md-3 mt-1 col-lg mt-4 mt-md-0">
            <h5 className="display-md-2 u-p-20 u-fs-24">
              <a
                className="text-center"
                href={`${locale}/restaurants/restaurant`}
              >
                <span className="fa fa-cutlery text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('restaurants')}</h2>
              </a>
            </h5>
          </div>

          <div className="col-md-4 mt-md-3 mt-1 col-lg mt-4 mt-md-0">
            <h5 className="display-md-2 u-p-20 u-fs-24">
              <a
                className="text-center"
                href={`${locale}/restaurants/coffee-shop`}
              >
                <span className="fa fa-coffee text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('coffeeShop')}</h2>
              </a>
            </h5>
          </div>

          <div className="col-md-4 mt-md-3 mt-1 col-lg-2 mt-4 mt-md-0">
            <h5 className="display-md-2 text-nowrap  u-p-20 u-fs-24">
              <a
                className="text-center"
                href={`${locale}/restaurants/quick-service`}
              >
                <span className="fa fa-spoon text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('quickService')}</h2>
              </a>
            </h5>
          </div>

          <div className="col-md-4 mt-md-3 mt-1 col-lg mt-4 mt-md-0">
            <h5 className="display-md-2 u-p-20 u-fs-24">
              <a
                className="text-center"
                href={`${locale}/restaurants/food-truck`}
              >
                <span className="fa fa-truck text-green u-fs-40 px-2" />
                <h2 className="text-center h4 pt-3">{t('foodTrucks')}</h2>
              </a>
            </h5>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(BusinessTypeStrip)
