import React, { Component } from 'react'
import { Images } from '../Themes'
import { Header } from '../components/navbar'
import TryButton from '../components/TryButton'
import scrollToComponent from 'react-scroll-to-component';
import jsonp from "jsonp"
import { CircleSpinner } from "react-spinners-kit";
import { isMobile } from "react-device-detect";
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Select from 'react-select';

const customStyles = {
  overlay: {zIndex: 9999}
};

class FeaturesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: 'Get Free Demo',
      submitted: false,
      modalIsOpen: false,
      pkg: 'demo',
      form: {
        fullname: '',
        email: '',
        country: '',
        phoneNumber: '',
        workAddress: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formFields = this.formFields.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  openModal = (pkg='demo') => {
    this.setState({modalIsOpen: true, pkg: pkg})
  };

  closeModal = () => {
    this.setState({modalIsOpen: false, pkg: 'demo'})
  };

  selectCountry = (val) => {
    const { form } = this.state
    form['country'] = val.value
    this.setState({ form});
  }

  handleChange(field, event) {
    const { form } = this.state
    form[field] = event.target.value
    this.setState({ form });
  }

  formFields() {
    const { form, pkg } = this.state

    return [
              {name: "FNAME", value: form.fullname},
              {name: "PNUMBER", value: form.phoneNumber},
              {name: "COUNTRY", value: form.country},
              {name: "EMAIL", value: form.email},
              {name: "PKG", value: pkg},
              {name: "LANG", value: "English"}
            ]
  }

  handleSubmit(evt) {
    const { form } = this.state
    evt.preventDefault();

    if (form.workAddress.length !== 0) {
      return false;
    }

    const action = "https://heytasker.us17.list-manage.com/subscribe/post?u=f615cbdd540685ca1a63130c8&amp;id=35b99b0650"
    const fields = this.formFields()
    const values = fields.map(field => {
      return `${field.name}=${encodeURIComponent(field.value)}`;
    }).join("&");
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const email = form.email;
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/; //eslint-disable-line
    if (email.length && !regex.test(email)) {
      return this.setState({ status: "empty" })
    }
    this.sendData(url)
  };

  sendData(url) {
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: 'duplicate', submitted: true });
        setTimeout(() => {
          this.closeModal();
        }, 3000)
      } else if (err) {
        this.setState({ status: 'error' });
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
      } else {
        this.setState({ status: 'success', submitted: true });
        setTimeout(() => {
          this.closeModal();
        }, 3000)
      };
    });
  }


  renderButton() {
    const { buttonText } = this.state;
    const { t } = this.props;
    return(
        <button
          onClick={() => this.openModal(true)}
          className="btn btn-success my-4 px-4 mx-2 text-white"
        >
          {t(buttonText)}
        </button>
    )
  }

  renderContactForm() {
    const { submitted, form, status } = this.state;
    const { t, i18n } = this.props;
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';

    const countryOptions = [
      {value: "Saudi Arabia", label: t('Saudi Arabia')},
      {value: "United Arab Emirates", label: t('United Arab Emirates')},
      {value: "Qatar", label: t('Qatar')},
      {value: "Bahrain", label: t('Bahrain')},
      {value: "Egypt", label: t('Egypt')},
      {value: "Oman", label: t('Oman')},
      {value: "Jordan", label: t('Jordan')},
      {value: "Lebanon", label: t('Lebanon')},
      {value: "United States", label: t('United States')}
    ];

    const selectStyles = {
      menu: (provided) => ({
        ...provided,
        marginTop: "5%",
        fontSize: 19,
        zIndex: 9999999999,
      }),
      menuList: (provided) => ({
        ...provided,
        textAlign: textDirection,
      }),
      control: (provided) => ({
        ...provided,
        textAlign: textDirection,
        padding: 5,
        borderRadius: '1000em',
        zIndex: 9999999999,
      })
    }

    if (status === 'sending') {
      return (
        <div className="row">
          <div className="mx-auto justify-content-center">
            <CircleSpinner size={40} color="#686769" loading={true} />
          </div>
        </div>
      )
    }

    if (submitted) {
      return(
        <div dir={textDirection}>
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">
              <h1 className="h1 text-white text-center">
               {t("We will reach out to you very soon")}
              </h1>
            </div>
          </div>
        </div>
      )
    }

    return(
      <div dir={textDirection}>
        <div className="row">
          <div className="col-lg-6 mx-auto text-center">
            <h1 className="h1 text-white text-center">
              {t("Discover how to improve your business with us")}
            </h1>
          </div>
        </div>

        <div className="row mt-5" dir={textDirection}>
          <div className="col-lg-12 mx-auto text-center">
            <form onSubmit={this.handleSubmit} dir={textDirection}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input name="FNAME" type="text" className="form-control border-0 p-3" placeholder={t("Your full name")} value={form.fullname} onChange={(value) => this.handleChange('fullname', value)} required/>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input name="PNUMBER" type="text" className="form-control border-0 p-3" placeholder={t("Your Phone number")} value={form.phoneNumber} onChange={(value) => this.handleChange('phoneNumber', value)} required/>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6 u-mb-30">
                      <Select
                        className=""
                        placeholder={t("Select Country")}
                        defaultValue={form.country}
                        isRtl={false}
                        isSearchable={false}
                        name="country"
                        options={countryOptions}
                        styles={selectStyles}
                        onChange={this.selectCountry}
                        required
                      />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input name="EMAIL" type="email" className="form-control border-0 p-3" placeholder={t("Your email")} value={form.email} onChange={(value) => this.handleChange('email', value)} required/>
                    </div>
                  </div>
                </div>
              </div>
              <input id='workAddress' name="workAddress" className="form-control" placeholder="workAddress" value={''} onChange={(value) => this.handleChange('workAddress', value)}/>
              {status === 'empty' && <p className="text-warning text-center u-fs-25 p-3">{t("You must write a correct e-mail")}</p>}
              <button className="btn btn-success u-w-170" type="submit">{t("Submit")}</button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t, i18n } = this.props;
    const videoLang = i18n.language === 'ar' ? 'https://www.youtube.com/embed/lfm2swoAJbM' : 'https://www.youtube.com/embed/WsEywWvITas';
    document.title = t('title');
    
    return (
      <div>
        <Header
          ShowToggle={true}
          onDemoClick={() => this.openModal('demo')}
          onHomeClick={() => scrollToComponent(this.home, { offset: -230, align: 'top'})}
          onFeaturesClick={() => scrollToComponent(this.features, { offset: isMobile ? -230 : 0, align: 'top'})}
          onPricingClick={() => scrollToComponent(this.pricing, { offset: isMobile ? -230 : 0, align: 'top'})}
          onHardwareClick={() => scrollToComponent(this.hardware, { offset: isMobile ? -230 : 0, align: 'top'})}
          demoButtonText={this.state.buttonText}
        />

        <section className="pb-0 pt-5 u-h-80vh" ref={(section) => { this.home = section; }}>
          <div className="overlay bg-white-opacity-0_1"></div>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-5 text-left mx-auto u-pt-20">
                <h1 className="u-fs-60 u-fw-900 text-dark-black">
                  {t("Everything For Your Business")}
                </h1>
                <p className="u-fs-22 text-dark-black u-lh-1_8 py-4">
                  {t("Simplifies running your business, by allowing you to streamline your orders")} 
                </p>

                  <TryButton props={this.props}/>
                  <button
                    className="btn btn-white my-4 px-4 mx-2 text-dark-black btn-play" data-fancybox="data-fancybox"
                    href={videoLang}
                  >
                    {t("Learn More")}
                    <i className="fa fa-play-circle pl-3"></i>
                  </button>


              </div>
              <div className="col-lg-6 u-pt-20">
                <img src={Images.dashboard_en} alt="FreshSUQ pos" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 align-self-right mx-5">
                <a href="https://apps.apple.com/sa/app/freshsuq-pos/id1549802659" rel="noopener noreferrer" target="_blank"><img src={Images.app_store} className='label-image mx-1' alt="FreshSUQ pos" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.freshsuq.pos" rel="noopener noreferrer" target="_blank"><img src={Images.play_store} className='label-image mx-1' alt="FreshSUQ pos" /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 mt-5 bg-light-grey">
          <div className="container">
            <div className="row text-center text-black">
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("Grocery Store")}</h5>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("Retail")}</h5>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("Food Truck")}</h5>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("Coffee Shop")}</h5>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("Bakary")}</h5>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <h5 className="display-md-2 u-p-10">{t("+More")}</h5>
              </div>
            </div>
          </div>
        </section>


        <section className="pt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 p-5 text-center">
                <img
                  className="modal-image u-rounded-10"
                  src={Images.model_scan_its}
                  alt="FreshSUQ Store"
                />
              </div>
              <div className="col-lg-6 p-5">
                <h2 className="text-black pl-2">{t("Automate Your Business")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Control your business")}
                </p>
                <TryButton props={this.props}/>
              </div>
            </div>
          </div>
        </section>

        <section className="p-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 p-5 ">
                <h2 className="h1 text-black pl-2">{t("Real-time Analytics")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Utilize your real-time sales")}
                </p>
                <TryButton props={this.props}/>
              </div>

              <div className="col-lg-6 u-pb-25 u-pt-30 text-center">
                <img
                  className="modal-image fadeInLeft"
                  src={Images.model_print_receipts}
                  alt="FreshSUQ analytics"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 p-5 text-center">
                <img
                  className="modal-image u-rounded-10"
                  src={Images.model_manage_discounts}
                  alt="FreshSUQ Store"
                />
              </div>
              <div className="col-lg-6 p-5">
                <h2 className="text-black pl-2">{t("Automate Your Business")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Control your business")}
                </p>
                <TryButton props={this.props}/>
              </div>
            </div>
          </div>
        </section>

        <section className="p-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 ml-auto">
                <h2 className="h1 text-black pl-2">{t("Real-time Analytics")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Utilize your real-time sales")}
                </p>
                <TryButton props={this.props}/>
              </div>

              <div className="col-lg-6 u-pb-25 u-pt-30 text-center">
                <img
                  className="modal-image fadeInLeft"
                  src={Images.model_multiple_locations}
                  alt="FreshSUQ analytics"
                />
              </div>
            </div>
          </div>
        </section>


        <section className="bg-darkerWhite px-5 pb-0 pt-0 my-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 pt-5  text-center">
                <img
                  className="client-image fadeInLeft"
                  src={Images.client_photo}
                  alt="FreshSUQ analytics"
                />
              </div>

              <div className="col-lg-6 pt-5  ml-auto">
                <h2 className="h1 text-black pl-2">{t("Real-time Analytics")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Utilize your real-time sales")}
                </p>
                <TryButton props={this.props}/>
              </div>
            </div>
          </div>
        </section>


        <section className="px-5 pb-0 my-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 ml-auto ">
                <h2 className="h1 text-black pl-2">{t("Real-time Analytics")}</h2>
                <p className="u-fs-22 pl-2 text-light-black u-lh-1_57 u-pt-30 my-4">
                  {t("Utilize your real-time sales")}
                </p>
                <TryButton props={this.props}/>
              </div>

              <div className="col-lg-7 pt-5 text-center">
                <img
                  className="img"
                  src={Images.laptop}
                  alt="FreshSUQ analytics"
                />
              </div>
            </div>

            <div className="row pt-5 ">
              <div className="col-lg-12 align-self-right">
                <img src={Images.app_store} className='label-image mx-1' alt="FreshSUQ pos" />
                <img src={Images.play_store} className='label-image mx-1' alt="FreshSUQ pos" />
              </div>
            </div>
          </div>
        </section>


        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          className="Modal"
          overlayClassName="ModalOverlay"
          contentLabel="Example Modal">

          <span onClick={this.closeModal} className="text-white fa ModalClose fa-times u-fs-50"></span>
          <div className="container u-h-100vh u-flex-center">
            {this.renderContactForm()}
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(FeaturesPage);
