import React from 'react'

const VideoSection = props => {
  const video =
    props.videoURL ||
    'https://video-previews.elements.envatousercontent.com/files/e1119dd3-670e-488b-b93d-895d26c12a80/video_preview_h264.mp4'

  return (
    <div className="videoContainerMobile container p-0 video-container ">
      <video
        autoPlay={'autoplay'}
        loop={true}
        className="video"
        muted={true}
        playsInline={true}
        data-wf-ignore="true"
        data-object-fit="cover"
        controls={false}
        data-origwidth="0"
        data-origheight="0"
      >
        <source src={video} data-wf-ignore="true" />
      </video>
    </div>
  )
}

export default VideoSection
