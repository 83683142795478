import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class Features extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container u-mt-80 u-mb-80">
        <div className="row u-mb-80">
          <div className="col-lg-8 mx-auto mt-5 mb-5 text-center">
            <h2 className="text-center">{t("Best Inventory Management to Increase Your Profit")}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 text-left u-mt-30">
            <div className="bg-white px-4 px-md-5 py-5 u-h-100p borderedCard rounded">
              <h4 className="u-fs-28 my-4">
                {t("Inventory Management")}
              </h4>
              <p className="u-fs-22 text-light-black u-lh-1_57 u-pt-30 my-4">
               {t("Manage your inventory in real-time")}
              </p>
            </div>
          </div>

          <div className="col-md-4 text-left u-mt-30">
            <div className="bg-white px-4 px-md-5 py-5 u-h-100p borderedCard rounded">
              <h4 className="u-fs-28 my-4">
               {t("Reporting and Analytics")}
              </h4>
              <p className="u-fs-22 text-light-black u-lh-1_57 u-pt-30 my-4">
                {t("Organizes all your data into a clear, friendly, real-time reports, accessible from your desktop or smartphone  Make informed decisions with customized reports")}
              </p>
            </div>
          </div>

          <div className="col-md-4 text-left u-mt-30">
            <div className="bg-white px-4 px-md-5 py-5 u-h-100p borderedCard rounded">
              <h4 className="u-fs-28 my-4">
                {t("Customer management")}
              </h4>
              <p className="u-fs-22 text-light-black u-lh-1_57 u-pt-30 my-4">
                {t("Build your customer database, Create customer profiles, and search your customers order history and personal contact information")}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Features);

