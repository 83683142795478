import React, { Component } from 'react'
import { Header } from '../components/navbar'
import TryButton from '../components/TryButton'
import scrollToComponent from 'react-scroll-to-component'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Pricing } from '../components/partials'
import FeaturePageHeader from '../components/FeaturePageHeader'
import SectionStrip from '../components/SectionStrip'
import { Helmet } from 'react-helmet'

class PricingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'Get Free Demo'
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (window.analytics) {
      window.analytics.track('Pricing Page View')
    }
  }

  openModal = pkg => {
    const { i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }
    window.open(`https://home.freshsuq.com/${locale}/setup`)
    if (window.analytics) {
      window.analytics.track(`Pricing Selected ${pkg}`)
    }
  }

  renderContactForm() {
    const { t, i18n } = this.props
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const zf_lang = i18n.language === 'ar' ? 'ar' : 'en'
    const formLink = `https://forms.zohopublic.com/alanhaikal/form/LandingDemo/formperma/CmPhTgc3uyNVDx-QTGuuv3ZJ6_G4rOM7w8DyVLJRDR4?zf_lang=${zf_lang}`

    return (
      <div dir={textDirection} className="p-2 u-rounded-10 py-5 u-h-80vh">
        <div className="row bg-indigo-opacity u-rounded-10 py-5">
          <div className="col-lg-12 mx-auto text-center">
            <h1 className="h1 text-white text-center">
              {t('Get Your Free Demo')}
            </h1>
            <p className="text-white text-center">{t('CompleteThisForm')}</p>
            <p className="text-white text-center">{t('YourDemoContains')}</p>
          </div>
        </div>

        <div className="row justify-content-center mt-3" dir={textDirection}>
          <div className="col-md-12 col-lg-12 mx-auto text-center px-0">
            <iframe
              frameBorder="0"
              title="demo request"
              className="zoho-demo-form"
              src={formLink}
            ></iframe>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t, i18n } = this.props

    return (
      <div>
        <Helmet>
          <title>{t('PricingTitle')}</title>
          <meta name="description" content={t('pricing.title')} />
        </Helmet>
        <Header
          ShowToggle={true}
          onDemoClick={() => this.openModal('demo')}
          onHomeClick={() =>
            scrollToComponent(this.home, { offset: -230, align: 'top' })
          }
          onFeaturesClick={() =>
            scrollToComponent(this.features, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onPricingClick={() =>
            scrollToComponent(this.pricing, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onHardwareClick={() =>
            scrollToComponent(this.hardware, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          demoButtonText={this.state.buttonText}
        />

        <section className="u-pt-25 mb-5">
          <div className="container">
            <Pricing t={t} onOpenModal={this.openModal} />
          </div>
        </section>

        <SectionStrip t={t} />

        <FeaturePageHeader
          i18n={i18n}
          title={t('pricing.title')}
          subtitle={''}
          renderButton={<TryButton props={this.props} />}
        />

        <div className="m-3 mb-5 u-rounded-10">{this.renderContactForm()}</div>
      </div>
    )
  }
}

export default withTranslation()(PricingPage)
