import React, { Component } from 'react'
import { Images } from '../../Themes'
import { Header } from '../../components/navbar'
import FeaturePageHeader from '../../components/FeaturePageHeader'
import TryButton from '../../components/TryButton'
import scrollToComponent from 'react-scroll-to-component'
import jsonp from 'jsonp'
import { CircleSpinner } from 'react-spinners-kit'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import Modal from 'react-modal'
import Select from 'react-select'
import FooterSalePage from '../../components/FooterSalePage'
import PersonSection from '../../components/PersonSection'
import Section from '../../components/Section'
import SectionStrip from '../../components/SectionStrip'

const customStyles = {
  overlay: { zIndex: 9999 }
}

class CustomerDisplayPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'Get Free Demo',
      submitted: false,
      modalIsOpen: false,
      pkg: 'demo',
      form: {
        fullname: '',
        email: '',
        country: '',
        phoneNumber: '',
        workAddress: ''
      }
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formFields = this.formFields.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (window.analytics) {
      window.analytics.track(`Customer Display Page Viewed`)
    }
  }

  openModal = (pkg = 'demo') => {
    this.setState({ modalIsOpen: true, pkg: pkg })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, pkg: 'demo' })
  }

  selectCountry = val => {
    const { form } = this.state
    form['country'] = val.value
    this.setState({ form })
  }

  handleChange(field, event) {
    const { form } = this.state
    form[field] = event.target.value
    this.setState({ form })
  }

  formFields() {
    const { form, pkg } = this.state

    return [
      { name: 'FNAME', value: form.fullname },
      { name: 'PNUMBER', value: form.phoneNumber },
      { name: 'COUNTRY', value: form.country },
      { name: 'EMAIL', value: form.email },
      { name: 'PKG', value: pkg },
      { name: 'LANG', value: 'English' }
    ]
  }

  handleSubmit(evt) {
    const { form } = this.state
    evt.preventDefault()

    if (form.workAddress.length !== 0) {
      return false
    }

    const action =
      'https://heytasker.us17.list-manage.com/subscribe/post?u=f615cbdd540685ca1a63130c8&amp;id=35b99b0650'
    const fields = this.formFields()
    const values = fields
      .map(field => {
        return `${field.name}=${encodeURIComponent(field.value)}`
      })
      .join('&')
    const path = `${action}&${values}`
    const url = path.replace('/post?', '/post-json?')
    const email = form.email
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/ //eslint-disable-line
    if (email.length && !regex.test(email)) {
      return this.setState({ status: 'empty' })
    }
    this.sendData(url)
  }

  sendData(url) {
    this.setState({ status: 'sending' })
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        this.setState({ status: 'duplicate', submitted: true })
        setTimeout(() => {
          this.closeModal()
        }, 3000)
      } else if (err) {
        this.setState({ status: 'error' })
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' })
      } else {
        this.setState({ status: 'success', submitted: true })
        setTimeout(() => {
          this.closeModal()
        }, 3000)
      }
    })
  }

  renderContactForm() {
    const { submitted, form, status } = this.state
    const { t, i18n } = this.props
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'

    const countryOptions = [
      { value: 'Saudi Arabia', label: t('Saudi Arabia') },
      { value: 'United Arab Emirates', label: t('United Arab Emirates') },
      { value: 'Qatar', label: t('Qatar') },
      { value: 'Bahrain', label: t('Bahrain') },
      { value: 'Egypt', label: t('Egypt') },
      { value: 'Oman', label: t('Oman') },
      { value: 'Jordan', label: t('Jordan') },
      { value: 'Lebanon', label: t('Lebanon') },
      { value: 'United States', label: t('United States') }
    ]

    const selectStyles = {
      menu: provided => ({
        ...provided,
        marginTop: '5%',
        fontSize: 19,
        zIndex: 9999999999
      }),
      menuList: provided => ({
        ...provided,
        textAlign: textDirection
      }),
      control: provided => ({
        ...provided,
        textAlign: textDirection,
        padding: 5,
        borderRadius: '1000em',
        zIndex: 9999999999
      })
    }

    if (status === 'sending') {
      return (
        <div className="row">
          <div className="mx-auto justify-content-center">
            <CircleSpinner size={40} color="#686769" loading={true} />
          </div>
        </div>
      )
    }

    if (submitted) {
      return (
        <div dir={textDirection}>
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">
              <h1 className="h1 text-white text-center">
                {t('We will reach out to you very soon')}
              </h1>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div dir={textDirection}>
        <div className="row">
          <div className="col-lg-6 mx-auto text-center">
            <h1 className="h1 text-white text-center">
              {t('Discover how to improve your business with us')}
            </h1>
          </div>
        </div>

        <div className="row mt-5" dir={textDirection}>
          <div className="col-lg-12 mx-auto text-center">
            <form onSubmit={this.handleSubmit} dir={textDirection}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input
                        name="FNAME"
                        type="text"
                        className="form-control border-0 p-3"
                        placeholder={t('Your full name')}
                        value={form.fullname}
                        onChange={value => this.handleChange('fullname', value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input
                        name="PNUMBER"
                        type="text"
                        className="form-control border-0 p-3"
                        placeholder={t('Your Phone number')}
                        value={form.phoneNumber}
                        onChange={value =>
                          this.handleChange('phoneNumber', value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6 u-mb-30">
                    <Select
                      className=""
                      placeholder={t('Select Country')}
                      defaultValue={form.country}
                      isRtl={false}
                      isSearchable={false}
                      name="country"
                      options={countryOptions}
                      styles={selectStyles}
                      onChange={this.selectCountry}
                      required
                    />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="input-group u-rounded-50 u-of-hidden u-mb-30">
                      <input
                        name="EMAIL"
                        type="email"
                        className="form-control border-0 p-3"
                        placeholder={t('Your email')}
                        value={form.email}
                        onChange={value => this.handleChange('email', value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <input
                id="workAddress"
                name="workAddress"
                className="form-control"
                placeholder="workAddress"
                value={''}
                onChange={value => this.handleChange('workAddress', value)}
              />
              {status === 'empty' && (
                <p className="text-warning text-center u-fs-25 p-3">
                  {t('You must write a correct e-mail')}
                </p>
              )}
              <button className="btn btn-success u-w-170" type="submit">
                {t('Submit')}
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t, i18n } = this.props
    const direction = i18n.language === 'ar' ? 'end' : 'start'
    document.title = t('CustomerDisplayTitle')

    return (
      <div>
        <Header
          ShowToggle={true}
          onDemoClick={() => this.openModal('demo')}
          onHomeClick={() =>
            scrollToComponent(this.home, { offset: -230, align: 'top' })
          }
          onFeaturesClick={() =>
            scrollToComponent(this.features, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onPricingClick={() =>
            scrollToComponent(this.pricing, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onHardwareClick={() =>
            scrollToComponent(this.hardware, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          demoButtonText={this.state.buttonText}
        />

        <FeaturePageHeader
          i18n={i18n}
          title={t('Improve your customer experience')}
          subtitle={''}
          renderButton={<TryButton props={this.props} />}
        />

        <SectionStrip t={t} />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.model_convenient_to_customers}
          title={t('CustomerDisplay.ConvenientForCustomers')}
          subtitle={t('CustomerDisplay.ConvenientForCustomersSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.model_works_offline}
          title={t('CustomerDisplay.WorksOffline')}
          subtitle={t('CustomerDisplay.WorksOfflineSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <PersonSection
          direction={direction}
          female="true"
          renderButton={<TryButton props={this.props} />}
          title={t('ProfessionalServices')}
          subtitle={t('ProfessionalServicesSubtitle')}
        />

        <FooterSalePage
          direction={direction}
          renderButton={<TryButton props={this.props} />}
          title={t('tryItForFree')}
          subtitle={t('tryItForFreeSubtitle')}
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          className="Modal"
          overlayClassName="ModalOverlay"
          contentLabel="Example Modal"
        >
          <span
            onClick={this.closeModal}
            className="text-white fa ModalClose fa-times u-fs-50"
          ></span>
          <div className="container u-h-100vh u-flex-center">
            {this.renderContactForm()}
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(CustomerDisplayPage)
