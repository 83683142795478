import React from 'react'

const NavbarToggle = props => (
  <button
    onClick={props.onClick}
    className={'navbar-toggler p-0 border-0'}
    data-toggle="collapse"
    data-target="#navbarNav"
  >
    <div
      className={
        'hamburger hamburger--spin js-hamburger ' +
        (props.collapsed ? '' : 'is-active')
      }
    >
      <div className="hamburger-box">
        <div className="hamburger-inner text-dark" />
      </div>
    </div>
    <p className="px-3 text-dark">{props.t('Menu')}</p>
  </button>
)

export default NavbarToggle
