import { takeLatest, all } from 'redux-saga/effects'

/* ------------- Types ------------- */

import { StartupTypes } from '../redux/StartupRedux'
import { NavTypes } from '../redux/NavRedux'
/* ------------- Sagas ------------- */
import { startup, startupSuccess } from './StartupSaga'
import { openNav, closeNav } from './NavSaga'


/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(StartupTypes.STARTUP_SUCCESS, startupSuccess),
    takeLatest(NavTypes.OPEN_NAV, openNav),
    takeLatest(NavTypes.CLOSE_NAV, closeNav),
  ])
}
