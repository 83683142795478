import React from 'react'

function onDemoClick(locale) {
  window.open(`https://home.freshsuq.com/${locale}/setup`)
  if (window.analytics) {
    window.analytics.track(`Mobile Clicked on Try Demo`)
  }
}

const MobileTryButton = ({ props }) => {
  const { t, i18n } = props
  let locale = 'en'

  if (i18n.languages.includes(i18n.language)) {
    locale = `${i18n.language.toString()}`
  }

  return (
    <button
      onClick={() => onDemoClick(locale)}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-green my-3 px-4 btn-block text-white"
    >
      <p className="u-fs-25 text-center">{t('Try Trial')}</p>
    </button>
  )
}

export default MobileTryButton
