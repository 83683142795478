import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Header } from '../components/navbar'
import GettingStartedLinks from '../components/GettingStartedLinks'

class GettingStartedPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    const top = document.getElementById('top')
    top.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  routeTo(path) {
    this.props.history.push(path)
  }

  render() {
    const { t } = this.props
    document.title = t('gettingStartedTitlePage')

    return (
      <div>
        <Header ShowToggle onDemoClick={true} />
        <section className="p-5 u-fs-24 text-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <article>
                  <img src="assets/img/blog/1_1.jpg" alt="" />
                  <h1 className="post-title mt-5 text-center">
                    {t('gettingStartedTitle')}
                  </h1>
                  <p className="text-center">{t('gettingStartedTitleBody')}</p>
                  <hr />

                  <h2 className="text-center mt-5 pt-5 text-center">
                    {t('HardwareSetup')}
                  </h2>
                  <ul className="list-unstyled u-fw-600 mt-5">
                    <li className="mb-3">
                      <h3>
                        <span className="fa fa-check-circle mr-3 text-green"></span>
                        {t('HardwareSetupSteps.list_of_hardware')}
                      </h3>
                      <ul className="list-unstyled u-fw-600 ml-5 my-5">
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.tablet')}
                        </li>
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.wifi_router')}
                        </li>
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.receipt_printer')}
                        </li>
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.cash_drawer')}
                        </li>
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.barcode_scanner')}
                        </li>
                      </ul>
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      {t('HardwareSetupSteps.download_app')}
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      {t('HardwareSetupSteps.register')}
                    </li>

                    <li className="mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      {t('HardwareSetupSteps.connect')}
                      <ul className="list-unstyled u-fw-600 ml-5">
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.connect_tablet')}
                        </li>
                        <li className="mb-3">
                          <span className="fa fa-check mr-3 text-green"></span>
                          {t('HardwareSetupSteps.connect_printer')}
                        </li>
                      </ul>
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      {t('HardwareSetupSteps.set_printer')}
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      {t('HardwareSetupSteps.set_barcode')}
                    </li>
                  </ul>

                  <a
                    href={'https://youtu.be/kZNA_i4q7Xs'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="u-fs-19 mb-3 mt-3 text-primary">
                      {t('howToDoIt')}{' '}
                      <span className="fa fa-play-circle"></span>
                    </p>
                  </a>

                  <hr />

                  <h2 className="text-center mt-5 pt-5 text-center">
                    {t('generalSettings')}
                  </h2>

                  <ul className="list-unstyled u-fw-600 mt-5">
                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('generalSettingsSteps.vat_id')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/_J0_CuuevrA'}
                          editLink={'https://home.freshsuq.com/settings'}
                        />
                      </div>
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('generalSettingsSteps.store')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/tIvK1TWgnMw'}
                          editLink={'https://home.freshsuq.com/locations'}
                        />
                      </div>
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('generalSettingsSteps.taxes')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/HiNgXm7canA'}
                          editLink={'https://home.freshsuq.com/taxes'}
                        />
                      </div>
                    </li>

                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('generalSettingsSteps.employees')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/GCWXSM6e5Vw'}
                          editLink={'https://home.freshsuq.com/employees'}
                        />
                      </div>
                    </li>
                  </ul>
                  <hr />

                  <h2 className="text-center mt-5 pt-5 text-center">
                    {t('InventorySetup')}
                  </h2>
                  <ul className="list-unstyled u-fw-600 mt-5">
                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('InventorySetupSteps.add_categories')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/Q15-Tdte7lM'}
                          editLink={'https://home.freshsuq.com/categories'}
                        />
                      </div>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <span className="fa fa-check-circle mr-3 text-green"></span>
                      <div className="column pt-4">
                        <p className="mb-0">
                          {t('InventorySetupSteps.add_products')}
                        </p>
                        <GettingStartedLinks
                          t={t}
                          videoLink={'https://youtu.be/tslAnLYoniA'}
                          editLink={'https://home.freshsuq.com/items'}
                        />
                      </div>
                    </li>
                  </ul>
                  <hr />
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation()(GettingStartedPage)
