import React from 'react'
import Faq from 'react-faq-component'

const textDirection = props => {
  const { i18n } = props
  const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
  return textDirection
}

const FAQ = props => {
  const { t } = props

  const data = {
    title: t('Faq'),
    rows: [
      {
        title: t('HowToUseFreshSUQ'),
        content: t('HowToUseFreshSUQMsg')
      },
      {
        title: t('DoesFreshsuqOffline'),
        content: t('DoesFreshsuqOfflineMsg')
      },
      {
        title: t('WhatDevicesWorkWithFreshsuq'),
        content: t('WhatDevicesWorkWithFreshsuqMsg')
      },
      {
        title: t('HowToSignup'),
        content: t('HowToSignupMsg')
      }
    ]
  }

  return (
    <section className=" pb-0 pt-5 my-5">
      <div
        dir={textDirection(props)}
        className="container d-flex justify-content-center"
      >
        <Faq data={data} />
      </div>
    </section>
  )
}

export default FAQ
