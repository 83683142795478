import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Header } from '../components/navbar'

class AboutUsPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    const top = document.getElementById('top')
    top.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  routeTo(path) {
    this.props.history.push(path)
  }

  render() {
    const { t } = this.props
    document.title = t('title')

    return (
      <div>
        <Header ShowToggle onDemoClick={true} />
        <section
          data-dark-overlay="6"
          data-init="parallax"
          className="u-py-100 u-pt-lg-200 u-pb-lg-150 u-flex-center about_hero"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center text-white">
                <h1 className="text-white text-center">{t('About Us')}</h1>
                <div className="u-h-4 u-w-50 bg-white rounded mx-auto my-4" />
                <h2 className="text-white text-center">
                  {t(
                    'We work hard to help you get the most out of your business'
                  )}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mx-auto">
                <p className="text-center u-fs-25 u-fw-700">
                  {t(
                    'At FreshSUQ, we’re passionate about providing a hassle-free platform for business owners'
                  )}
                  <br />
                  <br />
                  {t(
                    'We focus on creating solutions for those who need help improving their business workflow and reducing their costs'
                  )}
                  <br />
                  <br />
                  {t(
                    'We are truly excited to belong to a community of business owners who are eager to optimize their business'
                  )}
                  <br />
                  <br />
                  {t('success is defined by our customers')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation()(AboutUsPage)
