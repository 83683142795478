import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
// components
import { Header } from '../components/navbar'
import English from '../components/privacy/English'
import Arabic from '../components/privacy/Arabic'

class PrivacyPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    const top = document.getElementById('top')
    top.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  routeTo(path) {
    this.props.history.push(path)
  }

  render() {
    const { t, i18n } = this.props
    document.title = t('title')
    const lang = i18n.language

    return (
      <div>
        <Header ShowToggle onDemoClick={true} />
        {lang === 'en' ? <English /> : <Arabic />}
      </div>
    )
  }
}

export default withTranslation()(PrivacyPage)
