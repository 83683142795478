import React from 'react'
import { Images } from '../Themes'

const textDirection = direction => {
  const textDirection = direction === 'start' ? 'text-lg-left' : 'text-lg-right'
  return textDirection
}

const FooterSalePage = props => (
  <section className="px-5 pb-0 my-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7 p-lg-5 p-sm-3 order-lg-2 text-center">
          <img
            className="img"
            src={Images.ipad_device}
            alt="FreshSUQ analytics"
          />
        </div>

        <div className="col-lg-5 order-lg-1 ml-auto p-lg-5 pt-5">
          <h1
            className={`h1 u-fs-45 text-black pl-2 text-center ${textDirection(
              props.direction
            )}`}
          >
            {props.title}
          </h1>
          <p
            className={`u-fs-25 pl-2 text-black u-lh-1_57 u-pt-30 my-4 text-center ${textDirection(
              props.direction
            )}`}
          >
            {props.subtitle}
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default FooterSalePage
