import React from 'react'

const CTABox = props => {
  const { i18n } = props
  let locale = 'en'
  if (i18n.languages.includes(i18n.language)) {
    locale = `/${i18n.language.toString()}`
  }
  const requestDemoUrl = `${locale}/get-free-demo`

  const onDemoClick = props => {
    const { i18n } = props
    let locale = 'en'

    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }

    window.open(`https://home.freshsuq.com/${locale}/setup`)
  }

  return (
    <div className="row justify-content-center px-1 u-my-65 mr-0">
      <div className="bg-indigo col-10 col-xl-7 u-rounded-15 row justify-content-center p-3">
        <p className="u-fs-25 my-5 text-center col-10 text-white">
          {props.t('Discover how to improve your business with us')}
        </p>
        <button
          onClick={() => onDemoClick(props)}
          className="u-fs-25 btn btn-rounded col-10 col-xl-5 btn-green px-md-5 m-2"
        >
          <p className="text-center">{props.t('Try Trial')}</p>
        </button>
        <button className="u-fs-25 btn btn-rounded col-10 col-xl-5 btn-white px-md-5 m-2">
          <a href={requestDemoUrl}>
            <p className="text-center text-dark">{props.t('Get Free Demo')}</p>
          </a>
        </button>
      </div>
    </div>
  )
}

export default CTABox
