import React from 'react'

const FeaturePageHeader = props => {
  const { i18n } = props
  const lineSpacing = i18n.language === 'ar' ? 'u-lh-1_5' : 'u-lh-1_2'

  return (
    <section className="u-pt-md-150 pb-0 u-h-60vh">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-10 mx-auto u-pt-80">
            <h1
              className={`display-4 u-fw-600 text-black text-center ${lineSpacing}`}
            >
              {props.title}
            </h1>
            <p className="u-fs-22 text-black text-center u-lh-1_8 py-4">
              {props.subtitle}
            </p>
            <div className={`d-flex justify-content-center`}>
              {props.renderButton}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturePageHeader
