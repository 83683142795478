import React, { Component } from 'react'
import { Images } from '../Themes'
import { Header } from '../components/navbar'
import PersonSection from '../components/PersonSection'
import Section from '../components/Section'
import FAQ from '../components/Faq'
import SectionStrip from '../components/SectionStrip'
import BusinessTypeStrip from '../components/BusinessTypeStrip'
import CTABox from '../components/CTABox'
import TryButton from '../components/TryButton'
import scrollToComponent from 'react-scroll-to-component'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      buttonText: props.t('tryNow')
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { t, i18n } = this.props
    const direction = i18n.language === 'ar' ? 'end' : 'start'
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }
    const requestDemoUrl = `${locale}/get-free-demo`

    return (
      <React.Fragment>
        <Helmet>
          <title>{t('title')}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header
          ShowToggle={true}
          onHomeClick={() =>
            scrollToComponent(this.home, { offset: -230, align: 'top' })
          }
          onFeaturesClick={() =>
            scrollToComponent(this.features, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onPricingClick={() =>
            scrollToComponent(this.pricing, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onHardwareClick={() =>
            scrollToComponent(this.hardware, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          demoButtonText={this.state.buttonText}
        />

        <section className="pb-0 pt-0 pt-lg-4 u-h-90vh pb-5 mt-1 pt-4">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-5 text-left mx-auto u-pt-20 mb-lg-0 mb-2">
                <h2 className="u-fs-50 u-fw-600 text-lg-left text-center px-1 text-dark-black">
                  {t('Everything For Your Business')}
                </h2>
                <h1 className="u-fs-27 text-dark-black text-center u-lh-1_8 py-4 px-2">
                  {t(
                    'Simplifies running your business, by allowing you to streamline your orders'
                  )}
                </h1>

                <div className="row justify-content-center">
                  <a
                    className="btn my-4 btn-green px-4 mx-2 text-white u-fs-22 d-md-inline-block text-white"
                    href={`${locale}/pricing`}
                  >
                    <p className="u-fs-25 text-center">{t('seePricing')}</p>
                  </a>
                  <a
                    className="btn my-4 px-4 mx-2 text-white btn-dark u-fs-22 d-md-inline-block text-white"
                    href={requestDemoUrl}
                  >
                    <p className="u-fs-25 text-center">{t('Get Free Demo')}</p>
                  </a>
                  {/* <TryButton props={this.props} /> */}
                </div>
              </div>

              <div className="col-lg-6 u-p-20 mb-5 justify-content-center">
                <div className="mobileShow justify-content-center text-center">
                  <p className="text-center h3 mb-0 text-dark-black">
                    {t('approvedBy')}
                  </p>
                  <img
                    src={Images.zakatLogo}
                    height={'100%'}
                    width={'100%'}
                    className="zakatLogo"
                    alt="Zakat Logo"
                  />
                </div>
                <div
                  className="u-rounded-15 u-h-40vh d-flex justify-content-center heroBg"
                  alt="FreshSUQ POS"
                >
                  <img
                    className="u-rounded-15 heroImage mt-4 mb-0"
                    src={Images.dashboard_en}
                    alt="FreshSUQ POS"
                  />
                </div>

                <div className="row justify-content-center mb-4">
                  <a
                    href="https://apps.apple.com/sa/app/freshsuq-pos/id1549802659"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Images.app_store}
                      height={'100%'}
                      width={'100%'}
                      className="label-image mx-1"
                      alt="FreshSUQ pos"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.freshsuq.pos"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Images.play_store}
                      height={'100%'}
                      width={'100%'}
                      className="label-image mx-1"
                      alt="FreshSUQ pos"
                    />
                  </a>
                </div>
                <div className="mobileHide text-center my-3">
                  <p className="text-center h3 mb-0 text-dark-black">
                    {t('approvedBy')}
                  </p>
                  <img
                    src={Images.zakatLogo}
                    height={'90%'}
                    width={'100%'}
                    className="zakatLogo"
                    alt="Zakat Logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <BusinessTypeStrip t={t} />
        </section>

        <section className="p-3 p-lg-5 u-mt-60 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 u-fw-700 text-center">
                {t('POSSectionTitle')}
              </h2>
            </div>

            <div className="container m-lg-5 mt-5">
              <div className="row display-flex justify-content-center">
                <div className="col-lg-4 col-md-6 mt-5 mx-4 u-rounded-10 bg-light-grey">
                  <div className="px-4 px-md-5 py-5 justify-content-center">
                    <p className="h3 text-center">{t('home.PrintReceipts')}</p>
                    <p className="u-fs-24 mt-4 text-center">
                      {t('home.PrintReceiptsSubtitle')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row display-flex justify-content-center">
                <div className="col-lg-4 col-md-6 mt-4 mx-4 u-rounded-10 bg-light-grey">
                  <div className="px-4 px-md-5 py-5 justify-content-center">
                    <p className="h3 text-center">
                      {t('CustomerManagement.LoyaltyProgram')}
                    </p>
                    <p className="u-fs-24 mt-4 text-center">
                      {t('CustomerManagement.LoyaltyProgramSubtitle')}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-4 mx-4 u-rounded-10 bg-light-grey">
                  <div className="px-4 px-md-5 py-5 justify-content-center">
                    <p className="h3 text-center">
                      {t('CustomerManagement.HouseAccount')}
                    </p>
                    <p className="u-fs-24 mt-4 text-center">
                      {t('CustomerManagement.HouseAccountSubtitle')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CTABox t={t} i18n={i18n} />

        <section className="p-4 u-my-100 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 text-center u-fw-700">
                {t('InventorySectionTitle')}
              </h2>
              <p className="text-center u-fs-30 pt-4">
                {t('InventorySectionSubTitle')}
              </p>
            </div>

            <div className="col-lg-12 m-lg-5 mt-5">
              <div className="row justify-content-center">
                <div className="col-lg-6 u-pt-20 mt-3 row justify-content-center">
                  <img
                    className="u-rounded-15"
                    src={Images.pos_computer}
                    alt=""
                  />
                </div>
              </div>
              <div className="container">
                <div className="row display-flex justify-content-center">
                  <div className="col-lg-4 col-md-6 mt-5 u-rounded-10 bg-yellow mr-lg-2">
                    <div className="bg-white px-4 py-5 px-md-5 bg-yellow">
                      <p className="h3 u-pb-20 text-black text-center">
                        {t('AddNewProducts')}
                      </p>
                      <p className="h5 text-center text-black">
                        {t('AddNewProductsMsg')}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 u-rounded-10 mr-lg-2 bg-light-grey">
                    <div className="px-4 px-md-5 py-5 justify-content-center">
                      <p className="h3 text-center text-black">
                        {t('inventory.UnlimitedStock')}
                      </p>
                      <p className="u-fs-24 mt-4 text-center text-dark-black">
                        {t('inventory.UnlimitedStockSubtitle')}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 u-rounded-10 bg-purple ml-lg-2">
                    <div className="bg-white px-4 py-5 px-md-5 bg-purple">
                      <p className="h3  u-pb-20 text-white text-center">
                        {t('LowInventoryNotification')}
                      </p>
                      <p className="h5 text-center text-white">
                        {t('LowInventoryMsg')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-4 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 text-center u-fw-700">{t('Reports')}</h2>
              <p className="text-center u-fs-30 pt-4">
                {t('ReportsSubsection')}
              </p>
            </div>
          </div>
        </section>

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.sales_report}
          title={t('SalesReports')}
          addShadow={true}
          subtitle={t('SalesReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.tax_report}
          title={t('TaxReports')}
          addShadow={true}
          subtitle={t('TaxReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.payments_report}
          title={t('PaymentsReports')}
          addShadow={true}
          subtitle={t('PaymentsReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <SectionStrip t={t} />
        <CTABox t={t} i18n={i18n} />
        <FAQ t={t} i18n={i18n} />

        <PersonSection
          direction={direction}
          renderButton={<TryButton props={this.props} />}
          title={t('ProfessionalServices')}
          subtitle={t('ProfessionalServicesSubtitle')}
        />
      </React.Fragment>
    )
  }
}

export default withTranslation()(HomePage)
