import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import English from './english'

const Main = () => (
  <main>
    <Router>
      <Switch>
        <Route
          path="/"
          render={props => <English locale='en' {...props} />}
        />
      </Switch>
    </Router>
  </main>
)

export default Main
