import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PricingService from '../../lib/PricingService'
import FAQ from '../Faq'

class Pricing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        interval: 'monthly',
        locations_number: '1',
        registers_number: '1'
      },
      plansCostDetails: new PricingService('monthly', 1, 1).plansCostDetails
    }
  }

  onPriceClick = evt => {
    evt.preventDefault()
    const target = evt.target.target
    const showElms = document.querySelectorAll(`[id=${target}]`)
    const hideElms = document.querySelectorAll('.tab-pane.active')
    hideElms.forEach(elm => {
      elm.classList.remove('active')
      elm.classList.remove('show')
    })
    showElms.forEach(elm => {
      elm.classList.add('active')
      elm.classList.add('show')
    })
  }

  reCalculate = e => {
    e.preventDefault()
    const { form } = this.state
    form[e.target.name] = parseInt(e.target.value)

    if (e.target.name === 'locations_number') {
      form['registers_number'] = parseInt(e.target.value)
    }

    const plansCostDetails = new PricingService(
      form.interval,
      form.locations_number,
      form.registers_number
    ).plansCostDetails
    this.setState({ form, plansCostDetails })
  }

  onIntervalChange = interval => {
    const { form } = this.state
    form['interval'] = interval
    const plansCostDetails = new PricingService(
      form.interval,
      form.locations_number,
      form.registers_number
    ).plansCostDetails
    this.setState({ form, plansCostDetails })
  }

  showAdditionalPricing = plansCost => {
    const { form } = this.state
    const { i18n, t } = this.props
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'

    if (form.locations_number > 1 || form.registers_number > 1) {
      return (
        <div
          className="container mb-0 mt-4 mx-1 justify-content-center"
          dir={textDirection}
        >
          <div className="d-flex row justify-content-between mb-0 text-height-1">
            <small className="u-fs-16 text-dark-black">
              {t('basePlanCost')}
            </small>
            <small className="u-fs-16 text-dark-black">
              SAR {plansCost['basePlanCost']}
            </small>
          </div>
          <div className="d-flex row justify-content-between mb-0 text-height-1">
            <small className="u-fs-16 text-dark-black">
              {t('extraLocations')}
            </small>
            <small className="u-fs-16 text-dark-black">
              SAR {plansCost['extraLocationsCost']}
            </small>
          </div>
          <div className="d-flex row justify-content-between mb-0 text-height-1">
            <small className="u-fs-16 text-dark-black">
              {t('extraRegisters')}
            </small>
            <small className="u-fs-16 text-dark-black">
              SAR {plansCost['extraRegistersCost']}
            </small>
          </div>
        </div>
      )
    }
  }

  render() {
    const { t, i18n, onOpenModal } = this.props
    const { form, plansCostDetails } = this.state
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const monthly = form.interval === 'monthly'
    const annually = form.interval === 'annually'

    return (
      <section className="pricing py-5">
        <div className="container mobileHide">
          <div className="col-12 mx-auto bg-darkerWhite_2">
            <h1 className="text-center h1 pt-4">{t('firstMonthFree')}</h1>
            <h3 className="text-center h3 py-3">{t('subscribeAndUseNow')}</h3>
            <p className="text-center u-fs-22 pb-3">
              {t('creditCardNotReqired')}
            </p>
          </div>

          <form className="col-12 mx-auto bg-darkerWhite_2 text-dark-black">
            <div className="row d-flex justify-content-center">
              <div className="row">
                <fieldset className="m-4">
                  <label className="col-form-label">
                    {t('numberOfLocations')}
                  </label>
                  <div>
                    <input
                      type="number"
                      onChange={e => this.reCalculate(e)}
                      className="border-dark"
                      id="selected_locations_number"
                      name="locations_number"
                      placeholder="1"
                      min="1"
                      value={form.locations_number}
                    />
                  </div>
                </fieldset>

                <fieldset className="m-4">
                  <label className="col-form-label">
                    {t('numberOfRegisters')}
                  </label>
                  <div>
                    <input
                      type="number"
                      onChange={e => this.reCalculate(e)}
                      className="border-dark"
                      id="selected_registers_number"
                      name="registers_number"
                      placeholder="1"
                      min={form.locations_number}
                      value={form.registers_number}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>

          <div className="row d-flex justify-content-center">
            <div className="m-5">
              <ul
                id="myTab"
                role="tablist"
                className="d-flex justify-content-center nav nav-tabs nav-pills flex-column flex-sm-row text-center border-0 rounded-nav"
              >
                <li className="nav-item flex-sm-fill">
                  <a
                    id="annually-tab"
                    data-toggle="tab"
                    target="annually"
                    href="#annually"
                    role="tab"
                    onClick={() => this.onIntervalChange('annually')}
                    aria-controls="home"
                    aria-selected="true"
                    className="nav-link h5 border-dark px-5 mx-2 font-weight-bold"
                  >
                    {t('Annually')}
                  </a>
                </li>
                <li className="nav-item flex-sm-fill">
                  <a
                    id="monthly-tab"
                    data-toggle="tab"
                    href="#monthly"
                    target="monthly"
                    role="tab"
                    onClick={() => this.onIntervalChange('monthly')}
                    aria-controls="profile"
                    aria-selected="false"
                    className={`nav-link h5 border-dark px-5 font-weight-bold ${
                      monthly ? 'active' : ''
                    }`}
                  >
                    {t('Monthly')}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12 mx-auto mb-5">
              <div className="">
                <p className="text-center u-fs-20 text-dark-black pb-0 mb-2">
                  {annually && t('Annual payment')}
                  {monthly && t('Monthly payment')}
                </p>
                {annually && (
                  <p className="text-center u-fs-20 text-dark-black">
                    <span className="badge badge-danger p-2">
                      {t('discount')} %20
                    </span>
                  </p>
                )}
              </div>
            </div>

            <div className="col-11 mx-auto">
              <table className="table pricing-table table-hover table-bordered">
                <thead>
                  <tr className="active">
                    <th></th>
                    <th className="text-center">
                      <h3>{t('Basic')}</h3>
                    </th>
                    <th className="text-center is-highlighted">
                      <h3>{t('Plus')}</h3>
                    </th>
                    <th className="text-center">
                      <h3>{t('Pro')}</h3>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-dark-black ">
                  <tr>
                    <td className="text-center">
                      <br />
                      {t('Price')}
                    </td>

                    <td className="align-middle">
                      <div>
                        <p className="card-price text-center u-fs-28 text-dark-black">
                          SAR {plansCostDetails['basic']['totalCost']}{' '}
                          <span className="period">
                            /{annually && t('Annual')}
                            {monthly && t('month')}
                          </span>
                        </p>
                        {this.showAdditionalPricing(plansCostDetails['basic'])}
                      </div>
                    </td>
                    <td className="align-middle is-highlighted">
                      <div>
                        <p className="card-price text-center u-fs-28 text-dark-black">
                          SAR {plansCostDetails['advanced']['totalCost']}{' '}
                          <span className="period">
                            /{annually && t('Annual')}
                            {monthly && t('month')}
                          </span>
                        </p>
                        {this.showAdditionalPricing(
                          plansCostDetails['advanced']
                        )}
                      </div>
                    </td>

                    <td className="align-middle">
                      <div>
                        <p className="card-price text-center u-fs-28 text-dark-black">
                          SAR {plansCostDetails['pro']['totalCost']}{' '}
                          <span className="period">
                            /{annually && t('Annual')}
                            {monthly && t('month')}
                          </span>
                        </p>
                        {this.showAdditionalPricing(plansCostDetails['pro'])}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center  text-dark-black">
                      {t('Multiple locations')}
                    </td>
                    <td className="text-center  text-dark-black">1</td>
                    <td className="text-center  text-dark-black is-highlighted">
                      +1
                    </td>
                    <td className="text-center  text-dark-black">+1</td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('one license')}</td>
                    <td className="text-center  text-dark-black">+1</td>
                    <td className="text-center  text-dark-black is-highlighted">
                      +1
                    </td>
                    <td className="text-center text-dark-black ">+1</td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Unlimited Products')}</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Unlimited Employees')}</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Unlimited Customers')}</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Inventory Management')}</td>
                    <td className="text-center">{t('Basic Features')}</td>
                    <td className="text-center is-highlighted">
                      {t('Advanced')}
                    </td>
                    <td className="text-center">{t('Advanced')}</td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Inventory App')}</td>
                    <td className="text-center">{t('Basic Features')}</td>
                    <td className="text-center is-highlighted">
                      {t('Advanced')}
                    </td>
                    <td className="text-center">{t('Advanced')}</td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Reports & analytics')}</td>
                    <td className="text-center">{t('Basic Features')}</td>
                    <td className="text-center is-highlighted">
                      {t('Advanced')}
                    </td>
                    <td className="text-center">{t('Advanced')}</td>
                  </tr>

                  <tr>
                    <td className="text-center">
                      {t('CustomerManagement.HouseAccount')}
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Employee timesheet')}</td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">
                      {t('Delivery log & history')}
                    </td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('tablesManagement')}</td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">
                      {t('CustomerManagement.LoyaltyProgram')}
                    </td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">-</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('Customer display')}</td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">-</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">{t('kds')}</td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">-</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">
                      {t('QuickBooks / xero Integration')}
                    </td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">-</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-center">
                      {t('Third party integration')}
                    </td>
                    <td className="text-center">-</td>
                    <td className="text-center is-highlighted">-</td>
                    <td className="text-center">
                      <i className="fa fa-check text-dark-black fa-lg"></i>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td className="text-center">
                      <button
                        onClick={() => onOpenModal('basic')}
                        className="btn btn-block btn-green mt-5 text-uppercase"
                      >
                        {t('Select')}
                      </button>
                    </td>
                    <td className="text-center is-highlighted">
                      <button
                        onClick={() => onOpenModal('plus')}
                        className="btn btn-block btn-green mt-5 text-uppercase"
                      >
                        {t('Select')}
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        onClick={() => onOpenModal('pro')}
                        className="btn btn-block btn-green mt-5 text-uppercase"
                      >
                        {t('Select')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <FAQ t={t} i18n={i18n} />
            </div>
          </div>
        </div>

        <div className="container mobileShow">
          <div className="col-12 mx-auto bg-darkerWhite_2">
            <h1 className="text-center h1 py-3">{t('firstMonthFree')}</h1>
            <h3 className="text-center h3 py-3">{t('subscribeAndUseNow')}</h3>
            <p className="text-center u-fs-22 pb-3">
              {t('creditCardNotReqired')}
            </p>
          </div>

          <form className="mb-4 pb-3 bg-darkerWhite_2">
            <div className="row d-flex justify-content-center">
              <div className="row justify-content-center mx-0">
                <fieldset className="m-1 text-center">
                  <label className="col-form-label">
                    {t('numberOfLocations')}
                  </label>
                  <div>
                    <input
                      type="number"
                      onChange={e => this.reCalculate(e)}
                      className="border-dark"
                      id="selected_locations_number"
                      name="locations_number"
                      placeholder="1"
                      min="1"
                      value={form.locations_number}
                    />
                  </div>
                </fieldset>

                <fieldset className="m-1 text-center">
                  <label className="col-form-label">
                    {t('numberOfRegisters')}
                  </label>
                  <div>
                    <input
                      type="number"
                      onChange={e => this.reCalculate(e)}
                      className="border-dark"
                      id="selected_registers_number"
                      name="registers_number"
                      placeholder="1"
                      min="1"
                      value={form.registers_number}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>

          <div>
            <ul
              id="myTab"
              role="tablist"
              className="bg-light d-flex justify-content-center nav nav-tabs nav-pills flex-column flex-sm-row text-center border-0 rounded-nav"
            >
              <li className="nav-item flex-sm-fill">
                <a
                  id="annually-tab"
                  data-toggle="tab"
                  target="annually"
                  onClick={() => this.onIntervalChange('annually')}
                  href="#annually"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  className="nav-link h5 border-dark px-5 font-weight-bold"
                >
                  {t('Annually')}
                </a>
              </li>
              <li className="nav-item flex-sm-fill">
                <a
                  id="monthly-tab"
                  data-toggle="tab"
                  href="#monthly"
                  target="monthly"
                  onClick={() => this.onIntervalChange('monthly')}
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  className="nav-link h5 border-dark px-5 font-weight-bold active"
                >
                  {t('Monthly')}
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 pt-4">
            <div id="tabContent" className="tab-content">
              <div
                id="annually"
                role="tabpanel"
                aria-labelledby="annually-tab"
                className="tab-pane annually fade"
              >
                <p className="text-center u-fs-20 text-dark-black">
                  {t('Annual payment')}
                </p>
                {annually && (
                  <p className="text-center u-fs-20 text-dark-black">
                    <span className="badge badge-danger p-2">
                      {t('discount')} %20
                    </span>
                  </p>
                )}
              </div>
              <div
                id="monthly"
                role="tabpanel"
                aria-labelledby="monthly-tab"
                className="tab-pane monthly fade show active"
              >
                <p className="text-center u-fs-20 text-dark-black">
                  {t('Monthly payment')}
                </p>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-lg-4 mt-4">
              <div className="card mt-4 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    {t('Basic')}
                  </h5>
                  <div className="align-middle">
                    <div>
                      <p className="card-price text-center u-fs-28 text-dark-black">
                        SAR {plansCostDetails['basic']['totalCost']}{' '}
                        <span className="period">
                          /{annually && t('Annual')}
                          {monthly && t('month')}
                        </span>
                      </p>
                      {this.showAdditionalPricing(plansCostDetails['basic'])}
                    </div>
                  </div>
                  <hr />
                  <ul className="fa-ul" dir={textDirection}>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Multiple locations')} 1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('one license')} +1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Products')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Employees')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Customers')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory Management')} "{t('Basic Features')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory App')} "{t('Basic Features')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Reports & analytics')} "{t('Basic Features')}"
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('CustomerManagement.HouseAccount')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Employee timesheet')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Delivery log & history')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('tablesManagement')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('CustomerManagement.LoyaltyProgram')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Customer display')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('kds')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('QuickBooks / xero Integration')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Third party integration')}
                    </li>
                  </ul>
                  <button
                    onClick={() => onOpenModal('basic')}
                    className="btn btn-block btn-green mt-5 text-uppercase"
                  >
                    {t('Select')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="card mb-5 mb-lg-0 mt-4 ">
                <div className="card-body">
                  <div className="bg-green u-rounded-5">
                    <h5 className="text-center text-white">
                      {t('Recommended')}
                    </h5>
                  </div>
                  <h5 className="card-title text-muted text-uppercase text-center">
                    {t('Plus')}
                  </h5>
                  <div className="align-middle">
                    <div>
                      <p className="card-price text-center u-fs-28 text-dark-black">
                        SAR {plansCostDetails['advanced']['totalCost']}{' '}
                        <span className="period">
                          /{annually && t('Annual')}
                          {monthly && t('month')}
                        </span>
                      </p>
                      {this.showAdditionalPricing(plansCostDetails['advanced'])}
                    </div>
                  </div>
                  <hr />
                  <ul className="fa-ul" dir={textDirection}>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Multiple locations')} +1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('one license')} +1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Products')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Employees')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Customers')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory Management')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory App')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Reports & analytics')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('CustomerManagement.HouseAccount')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Employee timesheet')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Delivery log & history')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('tablesManagement')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('CustomerManagement.LoyaltyProgram')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Customer display')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('kds')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('QuickBooks / xero Integration')}
                    </li>
                    <li className="text-muted">
                      <span className="fa-li">
                        <i className="fa fa-times"></i>
                      </span>
                      {t('Third party integration')}
                    </li>
                  </ul>
                  <button
                    onClick={() => onOpenModal('plus')}
                    className="btn btn-block btn-green mt-5 text-uppercase"
                  >
                    {t('Select')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="card mt-4 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    {t('Pro')}
                  </h5>
                  <div className="align-middle">
                    <div>
                      <p className="card-price text-center u-fs-28 text-dark-black">
                        SAR {plansCostDetails['pro']['totalCost']}{' '}
                        <span className="period">
                          /{annually && t('Annual')}
                          {monthly && t('month')}
                        </span>
                      </p>
                      {this.showAdditionalPricing(plansCostDetails['pro'])}
                    </div>
                  </div>
                  <hr />
                  <ul className="fa-ul" dir={textDirection}>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Multiple locations')} +1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('one license')} +1
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Products')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Employees')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Unlimited Customers')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory Management')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Inventory App')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Reports & analytics')} "{t('Advanced')}"
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('CustomerManagement.HouseAccount')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Employee timesheet')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Delivery log & history')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('tablesManagement')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('CustomerManagement.LoyaltyProgram')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Customer display')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('kds')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('QuickBooks / xero Integration')}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa u-fs-20 text-success fa-check"></i>
                      </span>
                      {t('Third party integration')}
                    </li>
                  </ul>
                  <button
                    onClick={() => onOpenModal('pro')}
                    className="btn btn-block btn-green mt-5 text-uppercase"
                  >
                    {t('Select')}
                  </button>
                </div>
              </div>
            </div>
            <FAQ t={t} i18n={i18n} />
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(Pricing)
