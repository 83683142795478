import React, { Component } from 'react'
import { Images } from '../../Themes'
import { Header } from '../../components/navbar'
import BusinessPageHeader from '../../components/BusinessPageHeader'
import scrollToComponent from 'react-scroll-to-component'
import TryButton from '../../components/TryButton'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import FooterSalePage from '../../components/FooterSalePage'
import PersonSection from '../../components/PersonSection'
import VideoSection from '../../components/VideoSection'
import Section from '../../components/Section'
import RestaurantSectionStrip from '../../components/RestaurantSectionStrip'
import BusinessTypeSubsection from '../../components/BusinessTypeSubsection'
import { Helmet } from 'react-helmet'

class RestaurantPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'Get Free Demo'
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (window.analytics) {
      window.analytics.track(`Retail Store Page Viewed`)
    }
  }

  render() {
    const { t, i18n } = this.props
    const direction = i18n.language === 'ar' ? 'end' : 'start'
    document.title = t('restaurants') + t('TitlePart')

    return (
      <div>
        <Helmet>
          <title>{t('RestaurantsTitle')}</title>
          <meta name="description" content={t('restaurantSSubtitle')} />
        </Helmet>
        <Header
          ShowToggle={true}
          onDemoClick={() => this.openModal('demo')}
          onHomeClick={() =>
            scrollToComponent(this.home, { offset: -230, align: 'top' })
          }
          onFeaturesClick={() =>
            scrollToComponent(this.features, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onPricingClick={() =>
            scrollToComponent(this.pricing, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onHardwareClick={() =>
            scrollToComponent(this.hardware, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          demoButtonText={this.state.buttonText}
        />

        <BusinessPageHeader
          i18n={i18n}
          topTitle={t('forRestaurants')}
          title={t('restaurants')}
          subtitle={t('restaurantSSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <VideoSection videoURL="https://dn5k3liu00kar.cloudfront.net/stirring-penne-pasta-with-sauce-in-a-skillet-at-th-2022-03-30-20-38-31-utc.mp4" />
        <RestaurantSectionStrip t={t} />

        <BusinessTypeSubsection
          t={t}
          i18n={i18n}
          image={Images.ipad_restaurant}
          text={t('restaurantsText')}
        />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.tablesManagement}
          title={t('tablesManagement')}
          subtitle={t('tablesManagementSub')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.KDSIpad}
          title={t('kds')}
          subtitle={t('kdsSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.cdsIPAD}
          title={t('CustomerDisplay.ConvenientForCustomers')}
          subtitle={t('CustomerDisplay.ConvenientForCustomersSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.model_sales_trend_analysis}
          title={t('Reports & analytics')}
          subtitle={t('BusinessReports.SalesReportSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.model_easily_manage_your_customers}
          title={t('CustomerManagement.ManageCustomers')}
          subtitle={t('CustomerManagement.ManageCustomersSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.model_loyalty_program}
          title={t('CustomerManagement.LoyaltyProgram')}
          subtitle={t('CustomerManagement.LoyaltyProgramSubtitle')}
          renderButton={<TryButton props={this.props} />}
        />

        <PersonSection
          direction={direction}
          renderButton={<TryButton props={this.props} />}
          title={t('ProfessionalServices')}
          subtitle={t('ProfessionalServicesSubtitle')}
        />

        <FooterSalePage
          direction={direction}
          renderButton={<TryButton props={this.props} />}
          title={t('tryItForFree')}
          subtitle={t('tryItForFreeSubtitle')}
        />
      </div>
    )
  }
}

export default withTranslation()(RestaurantPage)
