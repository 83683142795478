import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class MiniHeader extends Component {
  onLangChange = lang => {
    const otherLang = lang === 'en' ? 'ar' : 'en'
    let currentPathname = window.location.pathname
      .replace(/\/+$/, '')
      .split('/')
      .filter(x => {
        return x !== ''
      })
    const hasLocale = currentPathname.includes(otherLang)

    if (hasLocale) {
      const index = currentPathname.indexOf(otherLang)
      currentPathname[index] = lang
      const newPath = currentPathname.join('/')
      window.location.replace(`/${newPath}`)
    } else {
      const newPath = [lang, ...currentPathname].join('/')
      window.location.replace(`/${newPath}`)
    }
  }

  renderLangButton() {
    const { i18n } = this.props
    let lang = ''

    if (i18n.languages.includes(i18n.language)) {
      lang = `${i18n.language.toString()}`
    }

    if (lang === 'ar') {
      return (
        <button
          className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent text-white"
          onClick={() => this.onLangChange('en')}
        >
          English
        </button>
      )
    }

    return (
      <button
        className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent text-white"
        onClick={() => this.onLangChange('ar')}
      >
        العربية
      </button>
    )
  }

  render() {
    return (
      <header className="mini-header-top bg-black d-none d-lg-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 my-2">
              <ul className="list-inline mb-0 text-white text-center text-md-left">
                <li className="list-inline-item mr-3">
                  <span className="fa fa-whatsapp mr-1 text-success"></span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/+966557585480"
                  >
                    +966557585480
                  </a>
                </li>
                <li className="list-inline-item">
                  <span className="la la-envelope-o mr-1"></span>
                  <a href="mailto:support@echotheme.com">sales@freshsuq.com</a>
                </li>
              </ul>
            </div>

            <div className="col-md-6 text-center text-md-right d-none d-lg-block my-2">
              <div className="row align-items-right">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item mr-3">
                    {this.renderLangButton()}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default withTranslation()(MiniHeader)
