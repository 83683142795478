import React from 'react'

const InventoryAppSection = props => {
  const { i18n, addShadow, t } = props
  const shadowClass = addShadow ? 'box-shadow-v1' : ''
  const textDirection =
    i18n.language === 'ar' ? 'text-lg-right' : 'text-lg-left'
  const titleTextSize = i18n.language === 'ar' ? '40' : '40'

  return (
    <section className="pt-5 pb-0">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-lg-6 p-5 text-center ${
              props.modelLeft ? 'order-lg-1' : 'order-lg-2'
            }`}
          >
            <img
              className={`invetory-modal-image ${shadowClass}`}
              src={props.image}
              alt="FreshSUQ Store"
            />
          </div>
          <div
            className={`col-lg-6 p-5 ${
              props.modelLeft ? 'order-lg-2' : 'order-lg-1'
            }`}
          >
            <h2
              className={`text-black ${titleTextSize} ${textDirection} text-center`}
            >
              {props.title}
            </h2>
            <ul
              className={`text-dark mt-5 u-fs-26 ${textDirection} text-center no-bullets`}
            >
              <li className="pt-3">{t('inventorySubheader')}</li>
              <li className="mt-5">{t('inventory.UnlimitedStock')}</li>
              <li className="pt-1">{t('inventory.InventoryCount')}</li>
              <li className="pt-1">{t('inventory.PurchaseOrders')}</li>
              <li className="pt-1">{t('LowInventoryNotification')}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InventoryAppSection
