import React from 'react'

const Arabic = props => (
  <section className="u-py-100 mt-5 u-pb-lg-150 u-flex-center">
    <div className="container">
      <div className="row justify-content-center box-shadow-v1 u-py-40">
        <div className="col-9 text-right">

          <p className="mb-5 text-right">تم تحديث هذه الوثيقة في 1/5/2021</p>
          
          <h1>سياسة الخصوصية</h1>
          <p className="mt-5 text-right">
تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك واستخدامها والكشف عنها عند استخدامك للخدمة، وتخبرك بحقوق الخصوصية الخاصة بك وكيف يحميك القانون.
نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدام الخدمة، فإنك توافق على جمع واستخدام المعلومات وفقا لسياسة الخصوصية هذه. </p>

          <h1>الترجمة الشفوية والتعاريف</h1>

          <h1>تفسير</h1>
          <p className="mt-5 text-right">الكلمات التي حرف الأولية هو رسملة لها معاني المعرفة في ظل الشروط التالية. 1- يكون للتعريف التالية نفس المعنى بغض النظر عما إذا كانت تظهر في المفرد أو الجمع.</p>

          <h1>التعاريف</h1>
          <p className="mt-5 text-right">لأغراض سياسة الخصوصية هذه:
الحساب يعني حساب فريد تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.
تشير الشركة (يشار إليها إما باسم "الشركة" أو "نحن" أو "نحن" أو "نحن" في هذه الاتفاقية) إلى FreshSUQ، الرياض.
ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك أو جهاز الجوال أو أي جهاز آخر من قبل موقع ويب، تحتوي على تفاصيل سجل التصفح الخاص بك على هذا الموقع من بين العديد من الاستخدامات.
البلد يشير إلى: المملكة العربية السعودية
يعني الجهاز أي جهاز يمكنه الوصول إلى الخدمة مثل الكمبيوتر أو الهاتف المحمول أو الكمبيوتر اللوحي الرقمي.
البيانات الشخصية هي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه.
تشير الخدمة إلى الموقع.
مزود الخدمة يعني أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة عن الشركة. وهي تشير إلى شركات أو أفراد من طرف ثالث يعملون لدى الشركة لتسهيل الخدمة أو لتقديم الخدمة نيابة عن الشركة أو لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة.
تشير بيانات الاستخدام إلى البيانات التي يتم جمعها تلقائيا، إما عن طريق استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة).
يشير الموقع إلى FreshSUQ، ويمكن الوصول إليه من com.freshsuq 
تعني الشخص الذي يقوم بالوصول إلى الخدمة أو استخدامها، أو الشركة، أو أي كيان قانوني آخر يقوم هذا الشخص بالوصول إليه أو استخدامه، حسب الاقتضاء.</p>

          <h1>جمع واستخدام بياناتك الشخصية</h1>
          <p className="mt-5 text-right"></p>

          <h1>أنواع البيانات التي تم جمعها</h1>
          <p className="mt-5 text-right">أثناء استخدام خدمتنا، قد نطلب منك تزويدنا ببعض المعلومات الشخصية التي يمكن استخدامها للاتصال بك أو التعرف عليك. قد تتضمن المعلومات الشخصية، على سبيل المثال لا الحصر:
عنوان البريد الإلكتروني
الاسم الأول واسم العائلة
رقم الهاتف
العنوان، الولاية، المقاطعة، الرمز البريدي، المدينة
بيانات الاستخدام</p>

          <h1>بيانات الاستخدام</h1>
          <p className="mt-5 text-right">يتم تجميع بيانات الاستخدام تلقائيا عند استخدام الخدمة.
قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت لجهازك (مثل عنوان IP) ونوع المتصفح وإصدار المتصفح وصفحات خدمتنا التي تزورها ووقت وتاريخ زيارتك والوقت الذي تقضيه في تلك الصفحات ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى.
عند الوصول إلى الخدمة بواسطة أو من خلال جهاز محمول، قد نقوم بجمع معلومات معينة تلقائيا، بما في ذلك، على سبيل المثال لا الحصر، نوع الجهاز المحمول الذي تستخدمه، ومعرف جهازك المحمول الفريد، وعنوان IP لجهازك المحمول، ونظام تشغيل هاتفك المحمول، ونوع متصفح الإنترنت المحمول الذي تستخدمه، ومعرفات الجهاز الفريدة وغيرها من البيانات التشخيصية.
قد نقوم أيضا بجمع المعلومات التي يرسلها متصفحك كلما قمت بزيارة خدمتنا أو عند الوصول إلى الخدمة بواسطة أو من خلال جهاز محمول.</p>

          <h1>تتبع التقنيات وملفات تعريف الارتباط</h1>
          <p className="mt-5 text-right">نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المشابهة لتتبع النشاط على خدمتنا وتخزين معلومات معينة. تقنيات التتبع المستخدمة هي إشارات وعلامات ونصوص لجمع المعلومات وتعقبها وتحسين خدمتنا وتحليلها. قد تتضمن التقنيات التي نستخدمها ما يلي:
ملفات تعريف الارتباط أو ملفات تعريف الارتباط للمتصفح. ملف تعريف الارتباط هو ملف صغير يوضع على جهازك. يمكنك أن تأمر المتصفح برفض جميع ملفات تعريف الارتباط أو للإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك، إذا لم تقبل ملفات تعريف الارتباط، فقد لا تتمكن من استخدام بعض أجزاء خدمتنا. ما لم تعدل إعداد المتصفح الخاص بك بحيث يرفض ملفات تعريف الارتباط، فقد تستخدم خدمتنا ملفات تعريف الارتباط.
فلاش كوكيز. قد تستخدم بعض ميزات خدمتنا كائنات مخزنة محلية (أو ملفات تعريف ارتباط Flash) لجمع وتخزين معلومات حول تفضيلاتك أو نشاطك على خدمتنا. لا تتم إدارة ملفات تعريف الارتباط فلاش من قبل إعدادات المتصفح نفسه مثل تلك المستخدمة لملفات تعريف الارتباط المستعرض. لمزيد من المعلومات حول كيفية حذف ملفات تعريف الارتباط فلاش، يرجى قراءة "أين يمكنني تغيير إعدادات تعطيل أو حذف الكائنات المشتركة المحلية؟" المتاحة في  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
منارات الويب. قد تحتوي أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات إلكترونية صغيرة تعرف باسم إشارات الويب (يشار إليها أيضا باسم صور gif واضحة وعلامات بكسل وصور gif أحادية البكسل) تسمح للشركة ، على سبيل المثال ، بحساب المستخدمين الذين زاروا تلك الصفحات أو فتحوا بريدا إلكترونيا وإحصاءات موقع الويب الأخرى ذات الصلة (على سبيل المثال ، تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم).
يمكن أن تكون ملفات تعريف الارتباط ملفات تعريف ارتباط "مستمرة" أو "جلسة". تبقى ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تذهب دون اتصال، بينما يتم حذف ملفات تعريف الارتباط الخاصة بالجلسة بمجرد إغلاق متصفح الويب الخاص بك.
نستخدم كل من ملفات تعريف الارتباط الخاصة بالجلسة وملفات تعريف الارتباط الدائمة للأغراض المبينة أدناه:
ملفات تعريف الارتباط الضرورية / الأساسية
النوع: ملفات تعريف ارتباط الجلسة
تدار من قبل: لنا
الغرض: تعتبر ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات المتوفرة من خلال الموقع ولتمكينك من استخدام بعض ميزاته. وهي تساعد على مصادقة المستخدمين ومنع الاستخدام الاحتيالية لحسابات المستخدمين. بدون ملفات تعريف الارتباط هذه، لا يمكن تقديم الخدمات التي طلبتها، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك بتلك الخدمات.
سياسة ملفات تعريف الارتباط / ملفات تعريف ارتباط قبول الإشعار
النوع: ملفات تعريف الارتباط الدائمة
تدار من قبل: لنا
الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد قبلوا استخدام ملفات تعريف الارتباط على الموقع.
ملفات تعريف الارتباط الوظيفية
النوع: ملفات تعريف الارتباط الدائمة
تدار من قبل: لنا
الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الخيارات التي تقوم بها عند استخدامك للموقع، مثل تذكر تفاصيل تسجيل الدخول أو تفضيلات اللغة. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة شخصية أكثر وتجنب اضطرارك إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.
لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها وخياراتك فيما يتعلق بملفات تعريف الارتباط، يرجى زيارة سياسة ملفات تعريف الارتباط الخاصة بنا أو قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا.</p>

          <h1>استخدام بياناتك الشخصية</h1>
          <p className="mt-5 text-right">يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:
تقديم خدماتنا وصيانتها، بما في ذلك مراقبة استخدام خدمتنا.
لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها حق الوصول إلى وظائف مختلفة من الخدمة المتوفرة لك كمستخدم مسجل.
من أجل تنفيذ العقد: تطوير عقد الشراء أو السلع أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة، والامتثال له والتعهد به.
للاتصال بك: للاتصال بك عن طريق البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل القصيرة أو غيرها من أشكال الاتصال الإلكتروني المكافئة، مثل إشعارات الدفع الخاصة بتطبيق الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية، عند الضرورة أو المعقولة لتنفيذها.
لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي قمت بشرائها أو الاستفسار عنها ما لم تكن قد اخترت عدم تلقي مثل هذه المعلومات.
لإدارة طلباتك: لحضور طلباتك إلينا وإدارتها.
بالنسبة لعمليات نقل الأعمال: يجوز لنا استخدام معلوماتك لتقييم أو إجراء عملية دمج أو تصفية أو إعادة هيكلة أو إعادة تنظيم أو حل أو بيع أو نقل آخر لبعض أو كل أصولنا، سواء كشركة جارية أو كجزء من الإفلاس أو التصفية أو إجراءات مماثلة، حيث تكون البيانات الشخصية التي نحتفظ بها عن مستخدمي خدمتنا من بين الأصول المنقولة.
لأغراض أخرى: قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات، وتحديد اتجاهات الاستخدام، وتحديد فعالية حملاتنا الترويجية وتقييم وتحسين ومنتجاتنا وخدماتنا التسويقية.
قد نشارك معلوماتك الشخصية في الحالات التالية:
مع مقدمي الخدمات: قد نشارك معلوماتك الشخصية مع مقدمي الخدمات لمراقبة وتحليل استخدام خدمتنا، للاتصال بك.
بالنسبة لعمليات نقل الأعمال: يجوز لنا مشاركة معلوماتك الشخصية أو نقلها فيما يتعلق بأي عملية دمج أو بيع لأصول الشركة أو تمويل أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى أو أثناء المفاوضات.
مع الشركات التابعة: قد نشارك معلوماتك مع الشركات التابعة لنا، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه. وتشمل الشركات التابعة لشركتنا الأم وأي شركات تابعة أخرى أو شركاء مشاريع مشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا.
مع شركاء العمل: قد نشارك معلوماتك مع شركائنا التجاريين لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة.
مع مستخدمين آخرين: عندما تشارك المعلومات الشخصية أو تتفاعل في المناطق العامة مع مستخدمين آخرين، قد يتم عرض هذه المعلومات من قبل جميع المستخدمين ويمكن توزيعها بشكل عام في الخارج.
بموافقتك: يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك.</p>

          <h1>الاحتفاظ ببياناتك الشخصية</h1>
          <p className="mt-5 text-right">ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريا للأغراض المنصوص عليها في سياسة الخصوصية هذه. سوف نحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كان مطلوبا منا الاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)، وحل النزاعات، وإنفاذ اتفاقياتنا وسياساتنا القانونية.
كما ستحتفظ الشركة ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام بشكل عام لفترة زمنية أقصر، إلا عندما يتم استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظائف خدمتنا، أو نكون ملزمين قانونيا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.</p>

          <h1>نقل بياناتك الشخصية</h1>
          <p className="mt-5 text-right">تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة وفي أي أماكن أخرى حيث توجد الأطراف المشاركة في المعالجة. وهذا يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة الكمبيوتر الموجودة خارج ولايتك أو مقاطعتك أو بلدك أو ولاية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الخاصة بولايتك القضائية.
موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات تمثل موافقتك على هذا النقل.
ستتخذ الشركة جميع الخطوات الضرورية بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى منظمة أو بلد ما لم تكن هناك ضوابط كافية بما في ذلك أمان بياناتك والمعلومات الشخصية الأخرى.</p>

          <h1>الكشف عن بياناتك الشخصية</h1>

          <h1>المعاملات التجارية</h1>
          <p className="mt-5 text-right">إذا كانت الشركة تحت عملية دمج أو استحواذ أو بيع أصول، فقد يتم نقل بياناتك الشخصية. سنقوم بتقديم إشعار قبل نقل بياناتك الشخصية وتصبح خاضعة لسياسة خصوصية مختلفة.</p>

          <h1>انفاذ</h1>
          <p className="mt-5 text-right">في ظل ظروف معينة، قد يطلب من الشركة الكشف عن بياناتك الشخصية إذا طلب منها ذلك بموجب القانون أو استجابة لطلبات صالحة من السلطات العامة (مثل محكمة أو وكالة حكومية).</p>

          <h1>المتطلبات القانونية الأخرى</h1>
          <p className="mt-5 text-right">يجوز للشركة الكشف عن بياناتك الشخصية إيمانا بحسن نية بأن مثل هذا الإجراء ضروري ل:
الامتثال لالتزام قانوني
حماية حقوق أو ممتلكات الشركة والدفاع عنها
منع أو التحقيق في الأخطاء المحتملة فيما يتعلق بالخدمة
حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور
الحماية من المسؤولية القانونية</p>

          <h1>أمن بياناتك الشخصية</h1>
          <p className="mt-5 text-right">أمن بياناتك الشخصية مهم بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة للإرسال عبر الإنترنت، أو طريقة التخزين الإلكتروني آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريا لحماية بياناتك الشخصية، لا يمكننا ضمان أمنها المطلق.</p>

          <h1>خصوصية الأطفال</h1>
          <p className="mt-5 text-right">لا تخاطب خدمتنا أي شخص دون سن 13 عاما. نحن لا نجمع معلومات شخصية عن علم من أي شخص دون سن 13 عاما. إذا كنت أحد الوالدين أو الوصي وكنت على علم بأن طفلك قد زودنا ببيانات شخصية، يرجى الاتصال بنا. إذا علمنا أننا جمعنا بيانات شخصية من أي شخص دون سن 13 عاما دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة هذه المعلومات من خوادمنا.
إذا كنا بحاجة إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك، وكان بلدك بحاجة إلى موافقة أحد الوالدين، فقد نطلب موافقة والديك قبل أن نجمع تلك المعلومات ونستخدمها.</p>

          <h1>روابط لمواقع أخرى</h1>
          <p className="mt-5 text-right">قد تحتوي خدمتنا على روابط لمواقع ويب أخرى لا يتم تشغيلها من قبلنا. إذا نقرت على رابط تابع لجهة خارجية، سيتم توجيهك إلى موقع هذا الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.
ليس لدينا أي سيطرة على أي من مواقع أو خدمات الطرف الثالث ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بها.</p>

          <h1>التغييرات في سياسة الخصوصية هذه</h1>
          <p className="mt-5 text-right">قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنعلمك بأي تغييرات من خلال نشر سياسة الخصوصية الجديدة على هذه الصفحة.
سنعلمك عبر البريد الإلكتروني و/أو إشعار بارز على خدمتنا، قبل أن يصبح التغيير ساري المفعول ونحدث تاريخ "آخر تحديث" في أعلى سياسة الخصوصية هذه.
ننصحك بمراجعة سياسة الخصوصية هذه بشكل دوري لأي تغييرات. تكون التغييرات في سياسة الخصوصية هذه فعالة عند نشرها على هذه الصفحة.</p>

          <h1>اتصل بنا</h1>
          <p className="mt-5 text-right">إذا كانت لديك أية أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا:</p>
          <p className="mt-5 text-right">support@freshsuq.com عن طريق البريد الإلكتروني </p>

        </div>
      </div>
    </div>
  </section>
)

export default Arabic;